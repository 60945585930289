import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { checkAttributes } from "../../../../utils/map";
import { selectGridCell, setSpecies, loadSites, clearGridCell } from "../../../../redux/reducers/map";
import Point from "@arcgis/core/geometry/Point";
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter";
import FeatureEffect from "@arcgis/core/layers/support/FeatureEffect";

/*

*/


function changeCursor(response){
    if (response.results.length > 0){
      document.getElementById("map-view-container").style.cursor = "pointer";
    } else {
      document.getElementById("map-view-container").style.cursor = "default";
    }
  }


const SensitivityGridLayer = (props) => {
    // Ref for Layer
    const gridlayerRef = useRef(null);
    const mapState = useSelector(state => state.map.mapState);
    const transparency = useSelector(state => state.map.transparency);
    const suitable = useSelector(state => state.map.suitable);
    const sensitivity_ranks = useSelector(state => state.map.sensitivity_ranks);
    const mapStateRef = useRef({});
    const suitableRef = useRef(false);
    const sensitivityRankRef = useRef(sensitivity_ranks);
    const withinScale = useRef(false);
    const highlightGraphic = useRef(null);
    const watchHover = useRef(null);
    const watchClick = useRef(null);
    const navigate = useNavigate ();
    let location = useLocation();

    // Layer Loaded state
    let [lyrLoaded, setLyrLoaded] = useState(false);
    let [lyrView, setLyrView] = useState(null);
    const dispatch = useDispatch();

    const rankFieldLookup = {
                            "SOLAR": "solar_sensitivity_rank",
                            "ONSHORE": "wind_sensitivity_rank",
                            "PWDIST": "pw_distr_sensitivity_rank",
                            "PWTRAN": "pw_trans_sensitivity_rank",
                            "OFFSHORE": "wind_sensitivity_rank"
                            }
    const suitableFieldLookup = {
                                "SOLAR": "solar_resource_suitable",
                                "ONSHORE": "wind_resource_suitable",
                                "OFFSHORE": "wind_resource_suitable"
                                }
 
    const colorsLookup = {
                        "1": "#55ff00",
                        "2": "#ffff00",
                        "3": "#ffaa00",
                        "4": "#ff0000"
                        }

    const updateSelection = async () => {
        // If mapstate contains latitude/longitude and grid cell is selected, get grid cell data and update in redux
        if (mapStateRef.current.lat && mapStateRef.current.energy && mapStateRef.current.country){

            let geom = new Point({ "latitude": mapStateRef.current.lat, "longitude": mapStateRef.current.long });
            let query = {
                geometry: geom,
                returnGeometry: true,
                spatialRelationship: "intersects",
                outFields: ["*"]
            }
            let results = await gridlayerRef.current.queryFeatures(query);
            if (results.features.length > 0){
                let rankfield = rankFieldLookup[mapStateRef.current.energy.code];
                let gid = results.features[0].attributes["GRID_ID"];
                let rank = results.features[0].attributes[rankfield];
                const featureFilter = new FeatureFilter({
                    where: `GRID_ID=${gid}`
                  });
                gridlayerRef.current.featureEffect = new FeatureEffect({
                    filter: featureFilter,
                    includedEffect: "drop-shadow(3px, 3px, 3px, black) opacity(2%)",
                    excludedEffect: "grayscale(10%) opacity(45%)"
                }); 
                let gridAttr = {...results.features[0].attributes, lat: mapStateRef.current.lat, long: mapStateRef.current.long};
                
                dispatch(selectGridCell({cellData: gridAttr}));

                let selectgraphic = results.features[0].clone()
                let color = rank ? colorsLookup[rank.toString()] || [255, 255, 255, 1] : [255, 255, 255, 1];
                selectgraphic.symbol = {type: "simple-fill", outline: { width: 2.3, color: color },color: [0, 0, 0, 0]};
                props.mapView.graphics.add(selectgraphic);

                let cellGeom = results.features[0].geometry;
                let latitude = results.features[0].geometry.centroid.latitude;
                let longitude = results.features[0].geometry.centroid.longitude;
                props.mapView.goTo({
                    target: [longitude + .01, latitude],
                    zoom: 12,
                    duration:500,
                    easing: "linear"
                }) 

                // Gather Species Data For Grid Cell

                let species = [];
                let speciestitle = `avistep_${mapStateRef.current.country.toLowerCase()}_${mapStateRef.current.energy.code.toLowerCase()}_species`; 
                // ie "avistep_ind_pwdist_species"
                let speciesTable = props.mapView.map.tables.find(table => table.title === speciestitle);
                if (mapStateRef.current.energy.code != "SOLAR" && speciesTable){
                    let query = {
                        where: `GRID_ID = ${gid}`,
                        outFields: ["*"]
                    }
                    let results = await speciesTable.queryFeatures(query);
                    species = results.features.length > 0 ? results.features.map(feat => feat.attributes): [];
                    dispatch(setSpecies({cellSpecies: species, country: mapStateRef.current.country.toLowerCase()}));
                } else {
                    dispatch(setSpecies({cellSpecies: [], country: mapStateRef.current.country.toLowerCase()}));
                }


                // Gather Sites Data for Grid Cell

                const sites_layers = [
                    "avistep_sites_habitat_seagrass",
                    "avistep_sites_habitat_mangrove",
                    "avistep_sites_habitat_coralreef",
                    "avistep_sites_iba_terrestrial",
                    "avistep_sites_iba_marinecoastal",
                    "avistep_sites_protected_terrestrial",
                    "avistep_sites_protected_marinecoastal",
                    "avistep_sites_seabird_colonies"
                ]

                const queryLayer = async (layername, geom) => {
                    let sitesLyr = props.mapView.map.layers.find(lyr => lyr.title === layername);
                    if (sitesLyr){
                        let query = {
                            spatialRelationship: "intersects",
                            geometry: geom,
                            outFields: ["*"],
                            returnGeometry: false
                        }
                        var results = await sitesLyr.queryFeatures(query);
                    }
                    return sitesLyr ? results.features.map(feat => feat.attributes): [];
                }

                let cellGeometry = cellGeom;
                let sites_results = await Promise.all(
                    sites_layers.map(async (layername) => {
                      let result = await queryLayer(layername,cellGeometry);
                      let sitename = layername.split("avistep_sites_")[1]; // avistep_sites_habitat_coralreef => "habitat_coralreef"
                      return { sitetype: sitename, sitedata: result};
                    })
                )

                dispatch(loadSites({sites: sites_results}));
            }
        } else {
            gridlayerRef.current.featureEffect = null;
        }

    }

    const updateDefinitionQuery = async () => {
        if (mapStateRef.current.country && mapStateRef.current.energy){
            let ranksfilter = false;
            let suitablefilter = false;
            let filters = [];

            if (sensitivityRankRef.current){
                // Add sensitivity ranks to be filtered
                let nonvisibleRanks = Object.keys(sensitivityRankRef.current).filter(key => sensitivityRankRef.current[key] === false);
                if (nonvisibleRanks.length > 0){
                    let rankField = rankFieldLookup[mapStateRef.current.energy.code];
                    let vals = nonvisibleRanks.join(",");
                    ranksfilter = `${rankField} NOT IN (${vals})`;
                    filters.push(ranksfilter);
                }
            }
            if (suitableRef.current && ["SOLAR","ONSHORE","OFFSHORE"].includes(mapStateRef.current.energy.code)){
                // Add filter for suitable areas
                let suitablefield = suitableFieldLookup[mapStateRef.current.energy.code];
                suitablefilter = `${suitablefield} = 1`;
                filters.push(suitablefilter);
            }
            
            // Combine all filters into definitionExpression clause
            let clause = !ranksfilter && !suitablefilter ? null : filters.join(` AND `);
            gridlayerRef.current.definitionExpression = clause;

        }
    }

    const updateTransparency = (trans) => {
        if (gridlayerRef.current){
            let opacity = trans / 100;
            gridlayerRef.current.opacity = opacity;
        }
    }

    const addHighlight = (graphic) => {
        let highlightID = highlightGraphic.current ? highlightGraphic.current.attributes?.OBJECTID : null;           
        if (highlightID != graphic.attributes.OBJECTID){
            if (highlightGraphic.current){props.mapView.graphics.remove(highlightGraphic.current);}
            let sym= {
                type: "simple-fill",
                outline: { width: 1.75, color: [255, 255, 255, 1] },
                color: [0, 0, 0, 0]
                }
            graphic.symbol = sym;
            highlightGraphic.current = graphic;
            props.mapView.graphics.add(graphic);
        }
        
    }

    const updateWatchers = async () => {
        if (mapStateRef.current.country && mapStateRef.current.energy && withinScale.current){
            if (watchHover.current == null){
                watchHover.current = props.mapView.on("pointer-move", async (evt) => {
                    var screenPoint = {x: evt.x,y: evt.y};
                    let response = await props.mapView.hitTest(screenPoint, { include: gridlayerRef.current })
                    //changeCursor(response);
                    if (response.results.length > 0 ){
                        addHighlight(response.results[0].graphic.clone());
                    }else{
                        if (highlightGraphic.current){props.mapView.graphics.remove(highlightGraphic.current);}
                        highlightGraphic.current = null;
                    }           
                });
            }
            if (watchClick.current == null){
                watchClick.current = props.mapView.on("click", async (evt) => {
                    var screenPoint = {x: evt.x,y: evt.y};
                    let response = await props.mapView.hitTest(screenPoint, { include: gridlayerRef.current })
                    if (response.results.length > 0 && withinScale.current){
                        let latitude = response.results[0].graphic.geometry.centroid.latitude;
                        let longitude = response.results[0].graphic.geometry.centroid.longitude;

                        let newstate = {"country": mapStateRef.current.country, "energy": mapStateRef.current.energy.code, "lat": latitude, "long": longitude};
                        const params = new URLSearchParams(newstate);
                        navigate({ pathname: location.pathname, search: params.toString(), replace: true });


                        highlightGraphic.current = null;
                        props.mapView.graphics.removeAll();
                    } 
                });   
            }
        }else{
            if (watchHover.current) {
                watchHover.current.remove();
                watchHover.current = null;
            }
            if (watchClick.current) {
                watchClick.current.remove();
                watchClick.current = null;
            }
        }
    }


    useEffect(() => {
        let scaleWatch = props.mapView.watch("scale", (scale) => {
            let inScaleRange = scale < 577792 ? true : false;
            if (inScaleRange != withinScale.current){
                withinScale.current = inScaleRange;
                if (gridlayerRef.current) { 
                    gridlayerRef.current.visible = inScaleRange;
                    updateWatchers();

                    if (!inScaleRange){
                        dispatch(clearGridCell());
                        props.mapView.graphics.removeAll();
                    }
                }
            }
        });
        return () => { 
            scaleWatch.remove(); 
        };
        
    }, []);

    useEffect(() => {
        mapStateRef.current = mapState;
        
        if (props.mapView){
            if (mapState.country && mapState.energy){
                let layertitle = `avistep_${mapState.country.toLowerCase()}_${mapState.energy.code.toLowerCase()}_grid`; // ie "avistep_ind_solar_grid"
                if (layertitle != gridlayerRef.current?.title){
                    if (gridlayerRef.current){ gridlayerRef.current.visible = false};

                    let gridLayer = props.mapView.map.layers.find(layer => layer.title === layertitle);
                    if (gridLayer) { // If sensitivity grid layer is present in the map, assign to layer reference and update vis
                        gridlayerRef.current = gridLayer;
                        gridLayer.popupEnabled = false;
                        if (withinScale.current){gridlayerRef.current.visible = true}
                        updateDefinitionQuery();
                        updateWatchers();
                    }
                }else{
                    updateDefinitionQuery();
                    updateWatchers();
                }
                if (gridlayerRef.current){ updateSelection();}
                 
            }else{
                if (gridlayerRef.current){ 
                    gridlayerRef.current.visible = false;
                    updateWatchers();
                };
            }
            
            
        }
          
    }, [mapState]);

    useEffect(() => {
        updateTransparency(transparency);
    }, [transparency]);

    useEffect(() => {
        suitableRef.current = suitable;
        updateDefinitionQuery();
    }, [suitable]);

    useEffect(() => {
        sensitivityRankRef.current = sensitivity_ranks;
        updateDefinitionQuery();
    }, [sensitivity_ranks]);

    return null;

}

export default SensitivityGridLayer;