import React, { useRef, useEffect, useState } from "react";
import './Legal.css';

function Cookies() {

  return (
    <div className="cookies">
      <h1>COOKIE SETTINGS</h1>
      <p>
      Cookie Settings content goes here.
      </p>
    </div>
  );
}



export default Cookies;
