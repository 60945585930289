import { call, put, takeLatest, select, delay } from "redux-saga/effects";
import { types as mapTypes, initMapState } from "../reducers/map";

const mapSelector = state => state.map;
const configSelector = state => state.config;

// Watchers
export function* watchMapActions() {
    yield takeLatest(mapTypes.CHECK_MAP_STATE, validateMapState);
    yield takeLatest(mapTypes.LOAD_SPECIES_DATA, loadSpecies);
    yield takeLatest(mapTypes.LOAD_SITES_DATA, loadSites);
}


// Actions
function* validateMapState(action) {
    /*
        Checks new map state items for validity, valid country, valid energy type, valid region, etc
    */
    const mapStore = yield select(mapSelector);
    const configStore = yield select(configSelector);
    try {
        let newMapState = {...mapStore.mapState};

        let country = action.payload.param?.country || null;
        let energy = action.payload.param?.energy || null;
        let region = action.payload.param?.region || null;
        let latitude = action.payload.param?.lat || null;
        let longitude = action.payload.param?.long || null;
        let updateNav = false;

        const checkCountryCode = obj => obj.code === country;
        let validcountry = configStore.countries.some(checkCountryCode);

        const checkEnergyCode = obj => obj.code === energy;
        let validenergy = configStore.energyTypes.some(checkEnergyCode);

        let validregion = region in configStore.regions || false;

        if (newMapState.country != country && validregion){
            validregion = false;
        }

        let newparams = {}

        newMapState['country'] = country;
        newMapState['lat'] = latitude;
        newMapState['long'] = longitude
        
        if (country && !validcountry){
            newMapState = {...initMapState}
            updateNav = true;
        }else if (validcountry){
            newparams.country = country
        }
        if (validcountry){
            let selectedcounty = configStore.countries.find(item => item.code === country);
            let energyOptions = configStore.energyTypes.filter((energytype) =>{
                return selectedcounty.energyoptions.includes(energytype.code)
            })
            yield put({
                payload: {countryEnergyOptions: energyOptions},
                type: mapTypes.SET_ENERGY_OPTIONS
            })
            if (!selectedcounty.energyoptions.includes(energy)){
                validenergy = false;
            }

            if (region && !validregion){
                updateNav = true;
                newMapState['region'] = null;
            }else if (validregion){
                newMapState['region'] = region
                let regioncountry = region.split(".")[0];
                newMapState['country'] = regioncountry;
                newparams.country = region.split(".")[0];
                newparams.region = region;
                if (regioncountry != country){
                    updateNav = true;
                }
            }else{
                newMapState['region'] = null;
            }
            if (energy && !validenergy){
                updateNav = true;
                newMapState['energy'] = null;
            }else if (validenergy){
                newMapState['energy'] = configStore.energyTypes.find(typ => typ.code == energy);
                newparams.energy = energy
                if (energy == "OFFSHORE" && validregion){
                    newMapState['region'] = null;
                }
            }else{
                newMapState['energy'] = null;
            }
            if (latitude && longitude){
                newMapState['lat'] = latitude;
                newMapState['long'] = longitude
                newparams.lat = latitude;
                newparams.long = longitude;
            }
        }
        
        yield put({
            payload: newMapState,
            type: mapTypes.SET_MAP_STATE
        })

        if (updateNav){  
            const param = new URLSearchParams(newparams);
            action.payload.navigate({ pathname: action.payload.path, search: param.toString(), replace: true })
        }

    } catch (e) {
        console.error("SAGA ERROR: setting state for map", e);
        yield put({
            payload: {
                error: e,
                msg: "SAGA ERROR: setting state for map"
            },
            type: mapTypes.MAP_ERROR
        })
    }
}


function* loadSpecies(action) {
    /*
        Adds species info from speciesReference and sets the cellSpecies data in map store
    */
    const configStore = yield select(configSelector);
    try {
        let cellSpecies = [...action.payload.cellSpecies];
        let species_lookup = configStore.speciesReference[action.payload.country] || {};
        
        let species = cellSpecies.map(bird =>{
            let speciesRef = species_lookup[bird.sps_code] || {};
            return { ...bird, ...speciesRef }
        })
        
        yield put({
            payload: {species: species},
            type: mapTypes.SET_SPECIES_DATA
        })

    } catch (e) {
        console.error("SAGA ERROR: setting species for cell", e);
        yield put({
            payload: {
                error: e,
                msg: "SAGA ERROR: setting species for cell"
            },
            type: mapTypes.MAP_ERROR
        })
    }
}


function* loadSites(action) {
    /*
        Adds sites (habitat, protected areas, ibas) from grid cell query and sets cellSites data in map store
    */
    const configStore = yield select(configSelector);
    try {
        if (action.payload.sites){
            for(let i = 0; i < action.payload.sites.length; i++) {
                yield put({
                    payload: {...action.payload.sites[i]},
                    type: mapTypes.SET_SITES_DATA
                })
            }
        }
        yield put({type: mapTypes.COMPLETE_SITES_DATA})

    } catch (e) {
        console.error("SAGA ERROR: setting sites for grid cell", e);
        yield put({
            payload: {
                error: e,
                msg: "SAGA ERROR: setting sites for grid cell"
            },
            type: mapTypes.MAP_ERROR
        })
    }
}

