import React, { useRef, useEffect, useState, useCallback } from "react";
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from "react-redux";
import { IconSource } from "../../../../utils/imagesource";
import { RightPanelImageSource } from "../../../../utils/imagesource";
import { formatLatitude, formatLongitude } from "../../../../utils/map";
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';
import './RightPanel.css';
import { Select } from "@material-ui/core";

Chart.register(ArcElement);

export const chartOptions = {
    layout: {
        padding: 30
    },
    cutout: '85%',
    borderRadius: 100,
    spacing: 10,
}
  
const RightPanel = (props) => {
    const dispatch = useDispatch();
    const mapStateRef = useRef({});
    // Controls to show/hide the panel or sections of the panel
    const [panelVisible, setPanelVisibility] = useState(false);
    const [minimizePanel, setMinimizePanel] = useState(false);

    const [showSensitiveSpeciesPanel, setShowSensitiveSpeciesPanel] = React.useState(true);
    const [showSensitiveFeaturesPanel, setShowSensitiveFeaturesPanel] = React.useState(false);
    const [showLandCoverPanel, setShowLandCoverPanel] = React.useState(false);
    const [showSensitiveSitesPanel, setShowSensitiveSitesPanel] = React.useState(false);
    const [containsSensitiveSites, setContainsSensitiveSites] = React.useState(false);

    // Data to display
    const [latitude, setLatitude] = React.useState(false);
    const [longitude, setLongitude] = React.useState(false);
    const [chartData, setChartData] = React.useState(false);
    const [energy, setEnergy] = useState(false);
    const [imageName, setImageName] = useState(false);
    const [image, setImage] = useState(false);
    const [title, setTitle] = useState(false);
    const [sensitivityScore, setSensitivityScore] = useState(false);
    const [sensitivityRank, setSensitivityRank] = useState(false);
    const [sensitivityName, setSensitivityName] = useState(false);
    const [sensitivityColor, setSensitivityColor] = useState(false);
    const [sensitivityDescription, setSensitivityDescription] = useState(false);
    const [country, setCountry] = useState(false);

    // Data passed to the panel
    const mapState = useSelector(state => state.map.mapState);
    const cellSelected = useSelector(state => state.map.cellSelected);
    const cellData = useSelector(state => state.map.cellData);
    const cellSpecies = useSelector(state => state.map.cellSpecies);
    const cellSites = useSelector(state => state.map.cellSites);
    const loadingSitesData = useSelector(state => state.map.loadingSitesData);
    const loadingSpeciesData = useSelector(state => state.map.loadingSpeciesData);

    // Hardcoded values related to sensitivity rank
    const sensitivityNames = {
        '1': 'Low',
        '2': 'Moderate',
        '3': 'High',
        '4': 'Very High',
    }

    const sensitivityColors = {
        '1': 'green',
        '2': 'yellow',
        '3': 'orange',
        '4': 'red',
    }

    const confidenceName = {
        '1': 'High',
        '2': 'Medium',
        '3': 'Low'
    }

    const sensitivityDescriptions = {
        '1': 'There is a low probability that installation here would effect local species.',
        '2': 'There is a moderate probability that installation here would effect local species.',
        '3': 'There is a strong probability that installation here would effect local species.',
        '4': 'There is a very strong probability that installation here would effect local species.',
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    // Set the Right Hand Panel visibility
    useEffect(() => {
        setPanelVisibility(cellSelected)
    }, [cellSelected]);

    useEffect(() => {
        mapStateRef.current = mapState;
        if (mapStateRef.current.country && mapStateRef.current.energy) {
            switch (mapStateRef.current.country) {
                case 'IND':
                    setImage(RightPanelImageSource['INDIA' + mapStateRef.current.energy.code]);
                    break;
                case 'NPL':
                    setImage(RightPanelImageSource['NEPAL' + mapStateRef.current.energy.code]);
                    break;
                case 'THA':
                    setImage(RightPanelImageSource['THAILAND' + mapStateRef.current.energy.code]);
                    break;
                case 'VNM':
                    setImage(RightPanelImageSource['VIETNAM' + mapStateRef.current.energy.code]);
                    break;
                default:
                    break;
            } 
        }
    }, [mapState]);

    // Parse all the available data
    useEffect(() => {
        if (cellData != null) {
            setLatitude(formatLatitude(cellData.lat));
            setLongitude(formatLongitude(cellData.long));
            setChartData({
                datasets: [
                    {
                      data: [
                        cellData.landcover_urban,
                        cellData.landcover_cultivated,
                        cellData.landcover_shrub,
                        cellData.landcover_herbaceous,
                        cellData.landcover_bare, 
                        cellData.landcover_forest,
                        cellData.landcover_moss_lichen,
                        cellData.landcover_herbaceous_wetlands,
                        cellData.lc_permanent_waterbodies,
                        cellData.landcover_snowice,
                      ],
                      backgroundColor: [
                        '#D73027',  // Urban
                        '#F46D43',  // Agricultural
                        '#FDAE61',  // Shrubs
                        '#FEE090',  // Herbaceous
                        '#FFFFBF',  // Bare
                        '#8AD293',  // Forest
                        '#E0F3F8',  // Moss
                        '#ABD9E9',  // Wetlands
                        '#74ADD1',  // Water
                        '#5692E1'   // Snow and Ice
                      ],
                      borderWidth: 0,
                    },
                  ],
                }
            );

            if (mapState.energy != null && mapState.energy){
                setEnergy(mapState.energy.code);
                switch (mapState.energy.code) {
                    case 'OFFSHORE':
                        setImageName('Offshore Windmills');
                        setTitle('OFFSHORE WIND');
                        if (cellData != null) {
                            if (cellData.wind_sensitivity) {
                                setSensitivityScore(cellData.wind_sensitivity);
                            }
                            if (cellData.wind_sensitivity_rank) {
                                setSensitivityRank(cellData.wind_sensitivity_rank);
                                setSensitivityName(sensitivityNames[cellData.wind_sensitivity_rank])
                                setSensitivityColor(sensitivityColors[cellData.wind_sensitivity_rank])
                                setSensitivityDescription(sensitivityDescriptions[cellData.wind_sensitivity_rank])
                            }
                        }
                        break;
                    case 'ONSHORE':
                        setImageName('Onshore Windmills');
                        setTitle('ONSHORE WIND');
                        if (cellData != null) {
                            if (cellData.wind_sensitivity) {
                                setSensitivityScore(cellData.wind_sensitivity);
                            }
                            if (cellData.wind_sensitivity_rank) {
                                setSensitivityRank(cellData.wind_sensitivity_rank);
                                setSensitivityName(sensitivityNames[cellData.wind_sensitivity_rank])
                                setSensitivityColor(sensitivityColors[cellData.wind_sensitivity_rank])
                                setSensitivityDescription(sensitivityDescriptions[cellData.wind_sensitivity_rank])
                            }
                        }
                        break;
                    case 'PWDIST':
                        setImageName('Distribution Power Lines');
                        setTitle('POWER DISTRIBUTION');
                        if (cellData != null) {
                            if (cellData.pw_distr_sensitivity) {
                                setSensitivityScore(cellData.pw_distr_sensitivity);
                            }
                            if (cellData.pw_distr_sensitivity_rank) {
                                setSensitivityRank(cellData.pw_distr_sensitivity_rank);
                                setSensitivityName(sensitivityNames[cellData.pw_distr_sensitivity_rank])
                                setSensitivityColor(sensitivityColors[cellData.pw_distr_sensitivity_rank])
                                setSensitivityDescription(sensitivityDescriptions[cellData.pw_distr_sensitivity_rank])
                            }
                        }
                        break;
                    case 'PWTRAN':
                        setImageName('Transmission Power Lines');
                        setTitle('POWER TRANSMISSION');
                        if (cellData != null) {
                            if (cellData.pw_trans_sensitivity) {
                                setSensitivityScore(cellData.pw_trans_sensitivity);
                            }
                            if (cellData.pw_trans_sensitivity_rank) {
                                setSensitivityRank(cellData.pw_trans_sensitivity_rank);
                                setSensitivityName(sensitivityNames[cellData.pw_trans_sensitivity_rank])
                                setSensitivityColor(sensitivityColors[cellData.pw_trans_sensitivity_rank])
                                setSensitivityDescription(sensitivityDescriptions[cellData.pw_trans_sensitivity_rank])
                            }
                        }
                        break;
                    case 'SOLAR':
                        setImageName('Photovoltaic Solar Panels');
                        setTitle('SOLAR PHOTOVOLTAIC');
                        if (cellData != null) {
                            if (cellData.solar_sensitivity) {
                                setSensitivityScore(cellData.solar_sensitivity);
                            }
                            if (cellData.solar_sensitivity_rank) {
                                setSensitivityRank(cellData.solar_sensitivity_rank);
                                setSensitivityName(sensitivityNames[cellData.solar_sensitivity_rank])
                                setSensitivityColor(sensitivityColors[cellData.solar_sensitivity_rank])
                                setSensitivityDescription(sensitivityDescriptions[cellData.solar_sensitivity_rank])
                            }
                        }
                        break;
                    default:
                        break;
                } 
            }
        }

        if (cellSites && 
            ((cellSites.iba_terrestrial && cellSites.iba_terrestrial.length > 0) ||
            (cellSites.protected_terrestrial && cellSites.protected_terrestrial.length > 0) ||
            (cellSites.iba_marinecostal && cellSites.iba_marinecostal.length > 0) ||
            (cellSites.protected_marinecostal && cellSites.protected_marinecostal.length > 0) ||
            (cellSites.seabird_colonies && cellSites.seabird_colonies.length > 0))) {
            setContainsSensitiveSites(true);
            showSenitiveSites();
        } else {
            setContainsSensitiveSites(false);
            showSensitiveSpecies();
        }
    }, [cellData, mapState, loadingSitesData, loadingSpeciesData])

    // Show Sensitive Sites Panel
    const showSenitiveSites = () => {
        setShowSensitiveFeaturesPanel(false);
        setShowSensitiveSpeciesPanel(false);
        setShowLandCoverPanel(false);
        setShowSensitiveSitesPanel(true);
    };

    // Show Sensitive Species Panel
    const showSensitiveSpecies = () => {
        setShowSensitiveFeaturesPanel(false);
        setShowLandCoverPanel(false);
        setShowSensitiveSitesPanel(false);
        setShowSensitiveSpeciesPanel(true);
    };

    // Show Land Cover Panel
    const showLandCover = () => {
        setShowSensitiveFeaturesPanel(false);
        setShowSensitiveSpeciesPanel(false);
        setShowSensitiveSitesPanel(false);
        setShowLandCoverPanel(true); 
    };

    // Show Sensitive Features Panel
    const showSensitiveFeatures = () => {
        setShowSensitiveSpeciesPanel(false);
        setShowLandCoverPanel(false); 
        setShowSensitiveSitesPanel(false);
        setShowSensitiveFeaturesPanel(true);
    };

    // Build the HTML
    return (
        <>
        {panelVisible && !minimizePanel &&
            <div className="right-panel">
                <div className="top-section">
                    <img className="top-image" alt="{imageName}" src={image}/>
                    <div className="location vertical-center">
                        <div className="location-icon"><img className="location-icon icon vertical-center" alt="Location" src={IconSource['LOCATIONPIN']}/></div>
                        <div>{latitude}, {longitude}</div>
                    </div>
                    
                    <div className="collapse" onClick={() => setMinimizePanel(true)}><img className="icon vertical-center" alt="Collapse" src={IconSource['COLLAPSE']}/></div>
                    <div className="title">{title}</div>
                    <div className="sub-title">Energy Assessment</div>
                    <div className="sensitivity-score">
                        <div className="sentitivity-score-title">Sensitivty Score</div>
                        <div className="sentitivity-score-value"><NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={sensitivityScore * 100}/></div>
                    </div>
                </div>
                <div className="sensitiviy-description">
                        Sensitivity assessed as <span className={sensitivityColor}>{sensitivityName}</span>. {sensitivityDescription}
                    </div>
                { !containsSensitiveSites && 
                <div className="tabs row">
                    <div className={showSensitiveSpeciesPanel ? "vertical-center col-5 active" : "vertical-center col-5"} onClick={showSensitiveSpecies}>Sensitive Species</div>
                    { energy != 'OFFSHORE' && <div className={showLandCoverPanel ? "vertical-center col-5 active" : "vertical-center col-5"} onClick={showLandCover}>Landcover Sensitivity</div>}
                    { energy == 'OFFSHORE' && <div className={showSensitiveFeaturesPanel ? "vertical-center col-5 active" : "vertical-center col-5"} onClick={showSensitiveFeatures}>Sensitive Features</div>}
                </div>
                }
                { containsSensitiveSites && 
                <div className="tabs3 row">
                    <div className={showSensitiveSitesPanel ? "vertical-center col-4 sensitive-site-tab" : "vertical-center col-4"} onClick={showSenitiveSites}>Sensitive Sites</div>
                    <div className={showSensitiveSpeciesPanel ? "vertical-center col-4 active" : "vertical-center col-4"} onClick={showSensitiveSpecies}>Sensitive Species</div>
                    { energy != 'OFFSHORE' && <div className={showLandCoverPanel ? "vertical-center col-4 active" : "vertical-center col-4"} onClick={showLandCover}>Landcover Sensitivity</div>}
                    { energy == 'OFFSHORE' && <div className={showSensitiveFeaturesPanel ? "vertical-center col-4 active" : "vertical-center col-4"} onClick={showSensitiveFeatures}>Sensitive Features</div>}
                </div>
                }
                { showSensitiveSpeciesPanel && !loadingSpeciesData &&
                    <div className="species-tab">
                        <div className="species-tab-header">
                            <div className="row species-header">
                                <div className="col-6">Species</div>
                                { (energy == 'OFFSHORE')  && <>
                                        <div className="col-3">Collisions</div>
                                        <div className="col-3">Disturbance</div>
                                    </>
                                }
                                { (energy != 'OFFSHORE')  && <>
                                        <div className="col-3">Sensitivity</div>
                                        <div className="col-3">Confidence</div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="species-tab-body">
                        {cellSpecies && cellSpecies.map((specie, index) => {
                            return <div key={index}  >
                                    <div className="row species-row" onClick={() => openInNewTab(specie.bl_factsheet)}>
                                        <div className="col-2 vertical-center"><div className="species-endanger-classification">{specie.rl_category}</div></div>
                                        <div className="col-5">
                                            <div className="common-name">{specie.common_name}</div>
                                            <div className="latin-name">{specie.scientific_name}</div>
                                        </div>
                                        {energy == 'OFFSHORE' && <div className="col-2"><NumberFormat decimalScale={0} displayType="text" value={specie.sensitivity_offshore_collision * 100}/></div>}
                                        {energy == 'ONSHORE' && <div className="col-2"><NumberFormat decimalScale={0} displayType="text" value={specie.sensitivity_wind * 100}/></div>}
                                        {energy == 'PWDIST' && <div className="col-2"><NumberFormat decimalScale={0} displayType="text" value={specie.sensitivity_pw_dis * 100}/></div>}
                                        {energy == 'PWTRAN' && <div className="col-2"><NumberFormat decimalScale={0} displayType="text" value={specie.sensitivity_pw_trans * 100}/></div>}

                                        {energy == 'OFFSHORE' && <div className="col-2"><NumberFormat decimalScale={0} displayType="text" value={specie.sensitivity_offshore_displaceme * 100}/></div>}
                                        {energy != 'OFFSHORE' && <div className="col-3">{confidenceName[specie.sps_confidence]}</div>}
                                    </div>
                                    <div className="separation-row">&nbsp;</div>
                                </div>
                            
                        })}
                        </div>
                    </div>
                }
                { showLandCoverPanel && energy != 'OFFSHORE' &&
                    <div className="land-cover-panel">
                        <Doughnut data={chartData} 
                            options={chartOptions}
                        />
                        <div className="land-cover-labels">
                            { !!(cellData.landcover_bare) && <p className="landcover-bare">Bare: <NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={cellData.landcover_bare}/></p>}
                            { !!(cellData.landcover_cultivated) && <p className="landcover-agricultural">Cultivated: <NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={cellData.landcover_cultivated}/></p>}
                            { !!(cellData.landcover_forest) && <p className="landcover-forest">Forest: <NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={cellData.landcover_forest}/></p>}
                            { !!(cellData.landcover_herbaceous) && <p className="landcover-herbaceous">Herbaceous: <NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={cellData.landcover_herbaceous}/></p>}
                            { !!(cellData.landcover_herbaceous_wetlands) && <p className="landcover-wetlands">Wetlands: <NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={cellData.landcover_herbaceous_wetlands}/></p>}
                            { !!(cellData.landcover_moss_lichen) && <p className="landcover-moss">Moss: <NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={cellData.landcover_moss_lichen}/></p>}
                            { !!(cellData.lc_permanent_waterbodies) && <p className="landcover-water">Water: <NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={cellData.lc_permanent_waterbodies}/></p>}
                            { !!(cellData.landcover_shrub) && <p className="landcover-shrub">Shrub: <NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={cellData.landcover_shrub}/></p>}
                            { !!(cellData.landcover_snowice) && <p className="landcover-snow">Snow/Ice: <NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={cellData.landcover_snowice}/></p>}
                            { !!(cellData.landcover_urban) && <p className="landcover-urban">Urban: <NumberFormat suffix={'%'} decimalScale={0} displayType="text" value={cellData.landcover_urban}/></p>}
                        </div>
                    </div>
                }
                { showSensitiveFeaturesPanel && energy == 'OFFSHORE' &&
                    <div className="sensitive-features-panel">
                        { (!cellSites || (!cellSites.habitat_mangrove || cellSites.habitat_mangrove.length == 0) &&
                          (!cellSites.habitat_seagrass || cellSites.habitat_seagrass.length == 0) &&
                          (!cellSites.habitat_coralreef || cellSites.habitat_coralreef.length == 0)) &&
                          <div>No sensitive features have been documented in this given area.</div>
                        }
                        {cellSites && ((cellSites.habitat_mangrove && cellSites.habitat_mangrove.length > 0) ||
                                       (cellSites.habitat_seagrass && cellSites.habitat_seagrass.length > 0) ||
                                       (cellSites.habitat_coralreef && cellSites.habitat_coralreef.length > 0)) &&
                            <div className="sensitive-features-tab-header">
                                Geographic Features
                            </div>
                        }
                        {cellSites && cellSites.habitat_mangrove && cellSites.habitat_mangrove.length > 0 &&
                            <>
                            <div className="sites-row" >
                                <div className="col-2"><img className="species" alt="Mangrove" src={RightPanelImageSource['MANGROVE']}/></div>
                                <div className="col-10 vertical-center">Mangrove</div>
                            </div>
                            <div className="separation-row">&nbsp;</div>
                            </>
                        }
                        {cellSites && cellSites.habitat_seagrass && cellSites.habitat_seagrass.length > 0 &&
                            <>
                            <div className="sites-row" >
                                <div className="col-2"><img className="species" alt="Seagrass" src={RightPanelImageSource['SEAGRASS']}/></div>
                                <div className="col-10 vertical-center">Seagrass</div>
                            </div>
                            <div className="separation-row">&nbsp;</div>
                            </>
                        }
                        {cellSites && cellSites.habitat_coralreef && cellSites.habitat_coralreef.length > 0 &&
                            <>
                            <div className="sites-row" >
                                <div className="col-2"><img className="species" alt="Coral Reef" src={RightPanelImageSource['CORALREEF']}/></div>
                                <div className="col-10 vertical-center">Coral Reef</div>
                            </div>
                            <div className="separation-row">&nbsp;</div>
                            </>
                        }
                    </div>
                }
                { showSensitiveSitesPanel && !loadingSitesData &&
                    <div className="sites-tab">
                        <div className="sites-tab-header">
                            <div className="row sites-header">
                                <div className="col-7">Site</div>
                                <div className="col-5">Type</div>
                            </div>
                        </div>
                        <div className="sites-tab-body">
                            {cellSites && cellSites.iba_terrestrial && cellSites.iba_terrestrial.map((site, index) => {
                                return <>
                                    <div key={index} className="sites-row" >
                                        <div className="col-7">{site.IntName}</div>
                                        <div className="col-5">Important Bird Area</div>
                                    </div>
                                    <div className="separation-row">&nbsp;</div>
                                    </>
                            })}
                            {cellSites && cellSites.iba_marinecostal && cellSites.iba_marinecostal.map((site, index) => {
                                return <>
                                    <div key={index} className="sites-row" >
                                        <div className="col-7">{site.IntName}</div>
                                        <div className="col-5">Important Bird Area</div>
                                    </div>
                                    <div className="separation-row">&nbsp;</div>
                                    </>
                            })}
                            {cellSites && cellSites.protected_terrestrial && cellSites.protected_terrestrial.map((site, index) => {
                                return <>
                                    <div key={index} className="sites-row" >
                                        <div className="col-7">{site.NAME}</div>
                                        <div className="col-5">Protected Area</div>
                                    </div>
                                    <div className="separation-row">&nbsp;</div>
                                    </>
                            })}
                            {cellSites && cellSites.protected_marinecostal && cellSites.protected_marinecostal.map((site, index) => {
                                return <>
                                    <div key={index} className="sites-row" >
                                        <div className="col-7">{site.NAME}</div>
                                        <div className="col-5">Protected Marine Area</div>
                                    </div>
                                    <div className="separation-row">&nbsp;</div>
                                    </>
                            })}
                            {cellSites && cellSites.seabird_colonies && cellSites.seabird_colonies.map((colony, index) => {
                                return <>
                                    <div key={index} className="sites-row" >
                                        <div className="col-7">{colony.common_name}</div>
                                        <div className="col-5">Searbird Colony &amp; Foraging Radius</div>
                                    </div>
                                    <div className="separation-row">&nbsp;</div>
                                    </>
                            })}
                        </div>                    
                    </div>
                }
                { (loadingSpeciesData || loadingSitesData) &&
                    <div className="progress"><CircularProgress color="inherit" size="4rem"/></div>
                }
            </div>
        }
        {panelVisible && minimizePanel &&
            <div  className="right-panel-minimized">
                <div className="collapse" onClick={() => setMinimizePanel(false)}><img className="icon vertical-center" alt="Collapse" src={IconSource['COLLAPSE']}/></div>
            </div>
        }
        </>
    )
}

export default RightPanel;
