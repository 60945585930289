import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as Link } from 'react-router-dom';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Caption from "../../../ui/shared/Caption";
import './Menu.css';



const useStyles = makeStyles((theme) => {
    return {
        
    }
})

const Menu = (props) => {
    const classes = useStyles();

    useEffect(() => {

    }, [])


    return (
        <div className="menu-root" >
             <div className="menu-head">
               <CloseSharpIcon className="closeBtn" onClick={props.closeEvent} fontSize="medium" style={{ color: "#FFFFFF", float: "left" }} />
               <Caption>AVISTEP</Caption>
            </div>
             <div className="menu-navlist">
                 <Link to='/' className="menu-link" title="Home">Home</Link>
                 <Link to='/#general' className="menu-link" title="About">About</Link>
                 <Link to='/#acknowledgements' className="menu-link" title="Acknowledgments">Acknowledgments</Link>
                 <Link to='/#contact' className="menu-link" title="Contact Us">Contact Us</Link>
                 <Link to='/#help' className="menu-link" title="Help">Help</Link>
             </div>   
        </div>
    )

}

export default Menu;

