import React, { useRef, useEffect, useState } from "react";
import { Route, Routes, useHistory, Redirect, Outlet, Link, useLocation } from "react-router-dom";

import { Nav, NavLink, Bars, NavMenu } from '../ui/NavItems';

import './Header.css';

function Header() {
  let location = useLocation();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    console.log('LOCATION CHANGED',location)
    if (location.pathname == 'map'){
      setOpen(false);
    }else{
      setOpen(true);
    }
  }, [location])


  return (
    
    <header className="App-header">
      <h3 className="App-title">BL</h3>
        <Nav>
          <Bars />
          <NavMenu>
            <NavLink to='/'>
              Home
            </NavLink>
            <NavLink to='/general' >
              General
            </NavLink>
            <NavLink to='/map' >
              AviStep
            </NavLink>
            <NavLink to='/terms' >
              Terms
            </NavLink>
            <NavLink to='/privacy' >
              Privacy
            </NavLink>
          </NavMenu>
        </Nav>
    </header>
  );
}



export default Header;
