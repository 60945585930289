import React, { useRef, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((props) => {
    return {
        root: {
            position: "absolute",
            display: props => `${props.display}`,
            top: props => `${props.mouseY}`,
            left: props => `${props.mouseX}`,
            fontWeight: '400',
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.60)',
            zIndex: '3',
            padding: '8px',
            borderRadius: '4px',
            maxWidth: '200px',
            minHeight: '20px',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&::before': {
                content: "",
                position: "absolute",
                width: "0",
                height: "0",
                left: "23px",
                bottom: "-42px",
                border: "10px solid transparent",
                borderTop: "32px solid rgb(193,193,193)",
                borderTop: "32px solid rgba(193,193,193,0.5)",
                zIndex: "2"
            },
            '&::after': {
                position: "absolute",
                width: "0",
                height: "0",
                left: "25px",
                bottom: "-31px",
                border: "8px solid transparent",
                borderTop: "24px solid #fff",
                zIndex: "3"
            }
        },
       
    }
});



const MapTooltip = (props) => {
    const editStatus = useSelector(state => state.map.editStatus);
    const [loaded, setLoaded] = useState(false);
    const [display, setDisplay] = useState('none');
    const [mouseX, setX] = useState('20px');
    const [mouseY, setY] = useState('40vw');
    const classes = useStyles({ display, mouseX, mouseY });
    const pointMap = useRef(null);

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);           
        }
    }, [])

    useEffect(() => {
        if (props.isOpen) {
            pointMap.current = props.mapView.on('pointer-move', function (event) {
                setX(event.x - 55 + 'px');
                setY(event.y - 90 + 'px');
            });   
            setDisplay('grid');
        } else {
            if (pointMap.current){
                pointMap.current.remove();
                pointMap.current = null;
            }
            setDisplay('none');
        }
    }, [props])


    return <div className={classes.root}>
                <div>{props.displaytext}</div>
            </div>
}

export default MapTooltip;
