import React, { useRef, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { IconSource } from "../../../../utils/imagesource";
import { startCountryReport, startCellReport } from "../../../../redux/reducers/map";


const useStyles = makeStyles((props) => {
    return {

        actionsmenu: {
            position: "absolute",
            top: "29px",
            right: props => `${props.right}`,
            zIndex: "4",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.50rem"
        },
        share: {
            backgroundColor: "rgba(1, 16, 51, 0.93)",
            height: '34px',
            width: '34px',
            borderRadius: '17px',
            '&:hover': {
                cursor: "pointer",
                border: "1px solid #0083CD",
                boxSizing: "border-box",
            },
        },
        print: {
            backgroundColor: "rgba(1, 16, 51, 0.93)",
            height: '34px',
            width: '34px',
            borderRadius: '17px',
            '&:hover': {
                cursor: "pointer",
                border: "1px solid #0083CD",
                boxSizing: "border-box",
            },
        },
        img: {
            margin: '0',
	        position: 'relative',
	        top: '30%',
	        transform: 'translateY(-50%)'
        }
        
    }
})


const MapActions = (props) => {
    const mapStateRef = useRef({});
    const dispatch = useDispatch();
    const [right, setRightPosition] = useState('1.5rem');
    const [showPrint, setShowPrint] = useState(false);
    const [printMode, setPrintMode] = useState(false);
    const classes = useStyles({ right });

    const mapState = useSelector(state => state.map.mapState);
    const cellSelected = useSelector(state => state.map.cellSelected);


    const shareMap = () => {
        const el = document.createElement('input');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const printMap = useCallback(
        () => generateReport(printMode),
        [printMode]
    );

    const generateReport = (printmode) => {
        if (printmode == 'country'){
            dispatch(startCountryReport({}));
        }else if (printmode == 'cell'){
            dispatch(startCellReport({}));
        }
    };

    useEffect(() => {
        if (cellSelected) {
            setRightPosition("62px");
            
        }else {
            setRightPosition("1.5rem");
        }
    }, [cellSelected])

    useEffect(() => {
        mapStateRef.current = mapState;
        if (mapState.country && mapState.energy != null){
            setShowPrint(true);
            if (cellSelected){
                setPrintMode("cell");
            }else {
                setPrintMode("country");
            }
        }else{
            setShowPrint(false);
            setPrintMode(false);
        }
    }, [mapState,cellSelected]);


    return <div className={classes.actionsmenu}>
                <div title="Share" className={classes.share}><img className={classes.img} alt="Share" src={IconSource['SHARE']} onClick={shareMap}/></div>
                {showPrint && <div title="Print" className={classes.print}><img className={classes.img} alt="Print" src={IconSource['PRINT']} onClick={printMap}/></div>}
            </div>

}

export default MapActions;
