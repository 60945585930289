import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector, Provider } from "react-redux";
import { useSearchParams, useLocation, useNavigate  } from "react-router-dom";

//Redux Imports
import { setMapReferenceData, setSpeciesReference } from "../../../../redux/reducers/config";
import { updateMapState, clearMap } from "../../../../redux/reducers/map";

// Esri imports
import ArcGISMap from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
//import SceneView from "@arcgis/core/views/SceneView";
import WebMap from "@arcgis/core/WebMap";
import esriConfig from "@arcgis/core/config";
import * as watchUtils from "@arcgis/core/core/watchUtils";

// Map Components
import SensitivityGridLayer from '../layers/SensitivityGridLayer';
import CountryLayer from '../layers/CountryLayer';
import RegionalBoundaries from '../layers/RegionalBoundaries';
import RegionalEnergy from '../layers/RegionalEnergy';

// Styled Components
import styled from "styled-components";


const Container = styled.div`
  height: 100%;
  width: 100%;
`;

// Component
const Map = (props) => {
    const containerID = "map-view-container";
    const mapDiv = useRef(null);
    const mapViewRef = useRef(null);
    let location = useLocation();
    const navigate = useNavigate();
    const [mapViewLoaded, setMapViewLoaded] = useState(false);
    const [mapDataLoaded, setMapDataLoaded] = useState(false);
    const dispatch = useDispatch();
    const config = useSelector(state => state.config);
    const dataloaded = useSelector(state => state.config.reference_data_loaded);

  useEffect(() => {
    let loaded = false;
    (async () => {
        try {
            if (true) { 

              let webmapID = config.webmapID;
              var mapConfig = {
                "portalItem": { "id": webmapID, "portal": { "url": config.portal } },
                "center": [79.48189730121511,23.49926361971663],
                "zoom": 4
              };

              // load the webmap
              const map = new WebMap({
                  ...mapConfig
              });

              map.when(async () => {
                  
              });

              // eslint-disable-next-line  
              const view = new MapView({
                map,
                container: mapDiv.current,
                center: [79.48189730121511,23.49926361971663],
                zoom: 4
              });

              view.ui._removeComponents(["zoom"]);
              view.when(async () => {
                mapViewRef.current = view;
                view.popup = null;
                // Override portal layer settings
                view.map.layers.forEach(layer => {
                  //console.log("LAYER",layer.title,layer.id);
                  layer.visible = false;
                  layer.outFields = ['*'];
                  if (layer.type === 'feature') {
                    layer.popupEnabled = false;
                  }
                  // set showSitesLayers to true to see all sites layers on the map
                  if (config.showSitesLayers){
                     if (config.sitesLayers.includes(layer.title)){
                       layer.visible = true;
                     }
                  }
                })

                if (!loaded){
                  let regionslyr = view.map.layers.find(layer => layer.title === "avistep_internal_boundaries");
                  let regions = {};
                  if (regionslyr){
                      let results = await regionslyr.queryFeatures({where: `1=1`, returnGeometry: false, outFields: ["GID_1","NAME_1"] });
                      regions = results.features.reduce((prevObj, feature) => ({ ...prevObj, [feature.attributes["GID_1"]]: feature.attributes["NAME_1"] }), {});
                  }

                  //SPECIES REFERENCE
                  let species_reference = {};
                  const buildSpeciesReference = async () => {
                      let species_lookup_tables = view.map.tables.filter(layer => layer.title.includes("_lookup_species"));
                      species_lookup_tables.forEach(async(lookuptable) => {
                      var country = lookuptable.title.split("_")[1];
                      var speciesresults = await lookuptable.queryFeatures({where: `1=1`, returnGeometry: false, outFields: ["*"] });
                      var species = speciesresults.features.reduce((prevObj, feature) => ({ ...prevObj, [`${feature.attributes["sps_code"]}`]: feature.attributes }), {});
                      species_reference[country] = {...species};
                      dispatch(setSpeciesReference(country,species));
                  })
                  }
                  await buildSpeciesReference();

                  dispatch(setMapReferenceData(regions));
                  setMapViewLoaded(true);
                  
                }

              })
        }  
        } catch (err) {
          console.log('ERR',err)  
          dispatch({
              'payload': {
                  error: err,
                  msg: "map failed to load"
              },
              'type': "MAP_ERROR"
          });
      }           
  })()
  return () => { 
    dispatch(clearMap());
    loaded = false
  };
}, [])


useEffect(() => {
  if (dataloaded){
    // Whenever url changes (query parameters), grab param and update map state
    let param = Object.fromEntries([...new URLSearchParams(location.search)]);

    dispatch(updateMapState({param:param, path:location.pathname, navigate: navigate}));
  }
}, [dataloaded,location])

useEffect(() => {
  if (mapViewLoaded){
    props.mapViewReady(mapViewRef.current);
  }
}, [mapViewLoaded])


 // Map Container
 return <Container>
            <Container id={containerID} ref={mapDiv}>
               {mapViewLoaded && <CountryLayer mapView={mapViewRef.current} layerStatus={props.layersStatus} />}
               {mapViewLoaded && dataloaded && <RegionalBoundaries mapView={mapViewRef.current} layerStatus={props.layersStatus} />}
               {mapViewLoaded && <RegionalEnergy mapView={mapViewRef.current} layerStatus={props.layersStatus} />}
               {mapViewLoaded && <SensitivityGridLayer mapView={mapViewRef.current} />}  
            </Container>
        </Container>;
};

export default Map;     