import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useLocation, useNavigate  } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import './SensitivitySelection.css';
import { IconSource } from "../../../../utils/imagesource";
import { setLayerTransparency, setSuitableFilter, setSensitivityRanks } from "../../../../redux/reducers/map";
import Toggle from "../../../ui/shared/Toggle";


const useStyles = makeStyles((props) => {
    return {
        color: {
            backgroundColor: props => `${props.color}`,
            borderRadius: "8px 0px 0px 8px"
        }
    }
})


const SensitivityCard = (props) => {
    const ranks = {"1": "Low", "2": "Moderate", "3": "High", "4": "Very High"};
    const color = props.color
    const classes = useStyles({ color });

    const toggleRank = (checked) => {
        props.onToggle({"rank":props.rank, "checked": checked});
    };
    const rankLabel = ranks[props.rank];

    
    return (
        <div className="sensitivity-card">
           <div className={classes.color}></div>
           <div className="labels">
               <div className="label1">{rankLabel}</div>
               {props.showAreas && <div className="label2">{props.stats.area.toLocaleString("en-US")} km<sup>2</sup></div>}
           </div>
           <div className="percent">
           {props.showAreas && <div>{props.stats.percentage}%</div>}
           </div>
           <div className="toggle">
               <Toggle enabled={props.toggledState} onClick={toggleRank}/>
           </div>
        </div>
    )

}

const SensitivitySelection = (props) => {
    const config = useSelector(state => state.config);
    const mapState = useSelector(state => state.map.mapState);
    const transparency = useSelector(state => state.map.transparency);
    const suitable = useSelector(state => state.map.suitable);
    const sensitivity_ranks = useSelector(state => state.map.sensitivity_ranks);
    const rankAreas = useSelector(state => state.map.rankAreas);

    const iconref = IconSource["NAVBACK"] || null;
    
    let [currentEnergy, setCurrentEnergy] = useState(null);
    let [suitableToggle, setSuitableToggle] = useState(false);
    let [suitableOnly,setSuitableOnly] = useState(false);
    let [visibleRanks,setVisibleRanks] = useState(sensitivity_ranks);
    let [rankStats, setRankStats] = useState({...rankAreas});
    let [areasFlag, setAreasFlag] = useState(false);

    //TODO transparency should be tied to layer transparency
    const navigate = useNavigate();
    const dispatch = useDispatch();
   
    const mapStateRef = useRef({});
    let [transparent, setTransparency] = useState(transparency);
    const togDef = true;

    useEffect(() => {
        setRankStats(rankAreas);
    }, [rankAreas])

    useEffect(() => {
        mapStateRef.current = mapState;
        if (mapState.energy != currentEnergy){
            setCurrentEnergy(mapState.energy);
        }
        let showAreas = !mapState.lat ? true: false;
        setAreasFlag(showAreas);
        
    }, [mapState, currentEnergy]);

    useEffect(() => {
        if (currentEnergy && ["ONSHORE","OFFSHORE"].includes(currentEnergy.code)){
            setSuitableToggle("Suitable Wind Speed Areas Only");
        } else if(currentEnergy && ["SOLAR"].includes(currentEnergy.code)){
            setSuitableToggle("Suitable Global Horizontal Irradiance (GHI) Areas Only");
        } else {
            setSuitableToggle(false);
        }
        
    }, [currentEnergy]);

    const navBack = () => {
        let param = Object.fromEntries([...new URLSearchParams(location.search)]);
        delete param["energy"];
        if (mapStateRef.current.lat){
            param["lat"] = mapStateRef.current.lat;
            param["long"] = mapStateRef.current.long;
        }
        const params = new URLSearchParams(param);
        navigate({ pathname: location.pathname, search: params.toString(), replace: true });
    }

    const transparencyChange = (event, newValue) => {
        setTransparency(newValue);
        dispatch(setLayerTransparency(newValue));
      };

    const toggleSuitable = (checked) => {
        setSuitableOnly(checked);
        dispatch(setSuitableFilter(checked));
    };

    const toggleSensitivity = (status) => {
        let ranks = {...visibleRanks};
        ranks[status.rank] = status.checked;
        setVisibleRanks(ranks);
        dispatch(setSensitivityRanks(ranks));
    };


    return (
        <div className="sensitivity-root">
            <div className="header">
               <div className="backnav" onClick={navBack}>
                    <img src={iconref} />
               </div>
               <div className="title">
                   <div className="title1">Energy Type</div>
                   {currentEnergy && <div className="title2">{currentEnergy.label}</div>}
                </div>
            </div>
            <div className="desc bodytext3"><div>Avian Sensitivity</div></div>
            <div className="cards">
                <SensitivityCard rank="1" color="#4EC943" showAreas={areasFlag} stats={rankStats["1"]} toggledState={visibleRanks["1"]} onToggle={toggleSensitivity}/>
                <SensitivityCard rank="2" color="#FFDB6B" showAreas={areasFlag} stats={rankStats["2"]} toggledState={visibleRanks["2"]} onToggle={toggleSensitivity}/>
                <SensitivityCard rank="3" color="#F37945" showAreas={areasFlag} stats={rankStats["3"]} toggledState={visibleRanks["3"]} onToggle={toggleSensitivity}/>
                <SensitivityCard rank="4" color="#F11919" showAreas={areasFlag} stats={rankStats["4"]} toggledState={visibleRanks["4"]} onToggle={toggleSensitivity}/>
            </div>
            <div className="suitable">
                {suitableToggle && 
                
                    <div>{suitableToggle}</div>}
                {suitableToggle && <Toggle enabled={suitableOnly} onClick={toggleSuitable}/>}
            </div>
            <div></div>
            <div className="transparency bodytext3">
                <div>Sensitivity Layer Transparency</div>
                <Slider className="slider" value={transparent} onChange={transparencyChange} aria-labelledby="Layer Transparency" />
            </div>
        </div>
    )

}

export default SensitivitySelection;

