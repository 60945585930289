import { call, put, takeLatest, delay, select } from "redux-saga/effects";
import esriConfig from "@arcgis/core/config";
import Basemap from "@arcgis/core/Basemap";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import Portal from "@arcgis/core/portal/Portal";
import PortalGroup from "@arcgis/core/portal/PortalGroup";
import config, { types as configTypes } from "../reducers/config";
import { types as mapTypes } from "../reducers/map";
import { getMapConfig, getMapToken, buildCountryLayers } from "../../utils/map";
import '../../mapref';

const configSelector = state => state.config;

async function getGroupItems(group) {
    let response = await group.queryItems();
    let results = response.results.map(result => {
        return {id: result.id, title: result.title, tags: result.tags}
    })
    return results;
}


// WATCHERS //
export function* watchFetchConfig() {
    yield takeLatest(configTypes.MAPCONFIG_FETCH, fetchMapConfig);
    yield takeLatest(configTypes.SET_MAPCONFIG, getLayerConfig);
    yield takeLatest(configTypes.SET_LAYERCONFIG, setConfigLoaded);
    yield takeLatest(configTypes.SET_MAP_REFERENCE, setReferenceLoaded);
  }
  
export function* watchTokenConfig() {
      yield takeLatest(configTypes.REFRESH_MAPTOKEN, fetchToken);
}

// WORKER //
// Fetch the map config info
function* fetchMapConfig(action) {
    try {
        const config = yield call(getMapConfig);
        let showMap = config.showTool == 'false' ? false : true;
        yield put({
            type: configTypes.SET_SHOWFEATURES,
            payload: { showTool: showMap}
        });

        esriConfig.portalUrl = config.portal;
        yield put({
            type: configTypes.SET_MAPCONFIG,
            payload: { tokeninfo: config.tokenInfo, mapconfig: config.mapConfig, portal: config.portal, groupID: config.groupID, webmapID: config.webmapID}
        });
        yield put({type: configTypes.REFRESH_MAPTOKEN});
    } catch (e) {
        yield put({
            type: configTypes.MAPCONFIG_FETCH_ERROR,
            payload: {
                error: e,
                msg: "SAGA ERROR: config / fetchMapConfig"
            }})
    }
}

function* getLayerConfig(action) {
    const configStore = yield select(configSelector);
    try {
        let portalID = action.payload.portal;
        let groupID = action.payload.groupID;

        global.mapref.esriID = IdentityManager;
        global.mapref.esriID.registerToken(action.payload.tokeninfo);

        let portal = new Portal(portalID);
        let currentGroup = new PortalGroup({id: groupID, portal: portal});
        const groupItems = yield call(getGroupItems,currentGroup);

        let countries = groupItems.find(item => item.tags.includes('layertype=countryboundary'));
        let species = groupItems.find(item => item.tags.includes('layertype=specieslookup'));

        let countryData = buildCountryLayers(groupItems);

        let layerconfig = {global_data: {species_lookup: species, country_boundary: countries},
                           country_data: countryData}

        yield put({
            type: configTypes.SET_LAYERCONFIG,
            payload: layerconfig
        });

        // Set the basemaps
        let loaded = yield call(portal.load);

        let basemaps = yield configStore.basemapOptions.map(bmap => {
            let basemap = Basemap.fromId(bmap.id);
            call(basemap.load);
            basemap.title = bmap.title;
            return basemap;
        });

        yield put({
            type: mapTypes.SET_BASEMAPS,
            payload: { basemaps: basemaps}
        });
        

        

    } catch (e) {
        console.log('EE',e)
        yield put({
            type: configTypes.MAPCONFIG_FETCH_ERROR,
            payload: {
                error: e,
                msg: "SAGA ERROR: config / getLayerConfig"
            }})
    }
}


// Retrieve new map token at set interval
function* fetchToken(action) {
    while (true) {
        try {
            yield delay(30000) // delay 30 mins
            const config = yield call(getMapToken);
            global.mapref.esriID.registerToken(config.tokenInfo);
            yield put({
                type: configTypes.SET_MAPTOKEN,
                payload: config.tokenInfo
            });
        } catch (e) {
            yield put({
                type: configTypes.MAPCONFIG_FETCH_ERROR,
                payload: {
                    error: e,
                    msg: "FETCH TOKEN ERROR: config/fetchToken"
                }
            })
        }
    }
}


function* setConfigLoaded(action) {
    try {

        yield put({type: configTypes.SET_LOADED});
            
    } catch (e) {
        yield put({
            type: configTypes.MAPCONFIG_FETCH_ERROR,
            payload: {
                error: e,
                msg: "SAGA ERROR: config / loaded"
            }})
    }
}


function* setReferenceLoaded(action) {
    try {

        yield put({type: configTypes.SET_DATA_LOADED});
            
    } catch (e) {
        console.log("EE",e)
        yield put({
            type: configTypes.MAPCONFIG_FETCH_ERROR,
            payload: {
                error: e,
                msg: "SAGA ERROR: config / data loaded"
            }})
    }
}


