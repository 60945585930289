import React, { useRef, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

import MapTooltip from "../../../ui/MapTooltip";


/*
This component is associated with and is used to control the behavior/visibility of the 
"avistep_internal_boundaries" feature layer in the AviStep webmap (published in AGO)
The avistep_internal_boundaries feature layer contains all of the regional/state boundaries for every country in the 
AviStep map. 
When a country is selected, this layer is visible and filtered to regions in that specific country

When looking at the sensitivity data for a specific region, this component will allow the user to hover over and
to select another region on the map.

*/



function changeCursor(response){
    if (response.results.length > 0){
      document.getElementById("map-view-container").style.cursor = "pointer";
    } else {
      document.getElementById("map-view-container").style.cursor = "default";
    }
  }



const RegionalBoundaries = (props) => {
    const layerTitle = "avistep_internal_boundaries";
    const regionsLayerRef = useRef(null);
    const mapStateRef = useRef({});
    const highlightGraphic = useRef(null);
    const watchHover = useRef(null);
    const watchClick = useRef(null);
    const withinScale = useRef(false);

    // Layer Loaded state
    let [lyrLoaded, setLyrLoaded] = useState(false);
    let [lyrView, setLyrView] = useState(null);
    let [showPopup, setShowPopup] = useState(false);
    let [regionName, setRegionName] = useState("");

    const config = useSelector(state => state.config);
    const mapState = useSelector(state => state.map.mapState);
    const navigate = useNavigate ();
    let location = useLocation();
    

    const addHighlight = (graphic) => {
        let highlightID = highlightGraphic.current ? highlightGraphic.current.attributes?.OBJECTID : null;           
        if (highlightID != graphic.attributes.OBJECTID){
            if (highlightGraphic.current){props.mapView.graphics.remove(highlightGraphic.current);}
            let fillOpacity = mapStateRef.current.energy ? 0 : 0.2;
            let sym = {type: "simple-fill",outline: { width: 1.75, color: [0,131,205, 1] },color: [76,129,205,fillOpacity]};
            graphic.symbol = sym;
            highlightGraphic.current = graphic;
            props.mapView.graphics.add(highlightGraphic.current);
        }
        
    }

    const selectRegion = async (graphic) => {
        if (!mapStateRef.current.lat && mapStateRef.current.energy?.code != "OFFSHORE"){
            let query = {
                where: `objectid = ${graphic.getObjectId()}`,
                returnGeometry: true,
                outFields: ["*"]
            }
            let results = await regionsLayerRef.current.queryFeatures(query);
            if (results.features.length > 0){
                let regioncode = results.features[0].attributes["GID_1"];
                let newstate = mapStateRef.current.energy ? {"country": mapStateRef.current.country, "region":regioncode, "energy": mapStateRef.current.energy.code} : {"country": mapStateRef.current.country, "region":regioncode}
                const params = new URLSearchParams(newstate);
                navigate({ pathname: location.pathname, search: params.toString(), replace: true });

                let resultgraphic = results.features[0].clone()
                resultgraphic.symbol = {type: "simple-fill",outline: { width: 2, color: [0,131,205, 1] },color: [76,129,205,0]};;
                props.mapView.graphics.add(resultgraphic);
                props.mapView.goTo({
                    target: results.features[0].geometry
                })
                 
            }
        }
      };

    const updateVisibility = async () => {
        if (mapStateRef.current.country && mapStateRef.current.energy?.code != "OFFSHORE" && withinScale.current){
            regionsLayerRef.current.visible = true;
        }else{
            if (highlightGraphic.current){props.mapView.graphics.remove(highlightGraphic.current);}
            props.mapView.graphics.removeAll();
            regionsLayerRef.current.visible = false;
        }
    }

    const updateDefinitionQuery = async () => {
        if (mapStateRef.current.country){
            let countryfield = "GID_0";
            let clause = `${countryfield} = '${mapStateRef.current.country}'`;
            regionsLayerRef.current.definitionExpression = clause;
        }else{
            regionsLayerRef.current.definitionExpression = null;
        }
    }

    const updateWatchers = async () => {
        if (mapStateRef.current.country && regionsLayerRef.current.visible){
            if (watchHover.current == null){
                watchHover.current = props.mapView.on("pointer-move", async (evt) => {
                    var screenPoint = {x: evt.x,y: evt.y};
                    let response = await props.mapView.hitTest(screenPoint, { include: regionsLayerRef.current })
                    changeCursor(response);
                    if (response.results.length > 0 && withinScale.current){
                        addHighlight(response.results[0].graphic.clone());
                        if (response.results[0].graphic.attributes["NAME_1"] != regionName){
                            setRegionName(response.results[0].graphic.attributes["NAME_1"]);
                            setShowPopup(true);
                        }else{
                            setShowPopup(false);
                        }
                    }else{
                        if (highlightGraphic.current){props.mapView.graphics.remove(highlightGraphic.current);}
                        setShowPopup(false);
                        setRegionName("");
                        highlightGraphic.current = null;
                    }           
                });
            }
            if (watchClick.current == null){
                watchClick.current = props.mapView.on("click", async (evt) => {
                    var screenPoint = {x: evt.x,y: evt.y};
                    let response = await props.mapView.hitTest(screenPoint, { include: regionsLayerRef.current })
                    if (response.results.length > 0 && withinScale.current){
                        props.mapView.graphics.removeAll();
                        selectRegion(response.results[0].graphic);
                        highlightGraphic.current = null;
                    } 
                });
            }
        }else{
            setShowPopup(false);
            if (watchHover.current) {
                watchHover.current.remove();
                watchHover.current = null;
            }
            if (watchClick.current) {
                watchClick.current.remove();
                watchClick.current = null;
            }
        }
    }

    useEffect(() => {
        
            let scaleWatch = props.mapView.watch("scale", (scale) => {
                let inScaleRange = scale > 577792 ? true : false;
                if (inScaleRange != withinScale.current){
                    withinScale.current = inScaleRange;
                    if (regionsLayerRef.current) { 
                        regionsLayerRef.current.visible = inScaleRange;
                        updateVisibility();
                        updateWatchers();
                    }
                }
            });
            (async () => {
                if (props.mapView && !lyrLoaded) {
                    mapStateRef.current = mapState;
                    let regionLyr = props.mapView.map.layers.find(layer => layer.title === layerTitle);
                    if (regionLyr) { // If regionlyr is present, update its visibility
                        regionsLayerRef.current = regionLyr;
                        updateDefinitionQuery();
                        updateVisibility();
                        updateWatchers();
                        setLyrLoaded(true);
            
                        if (mapStateRef.current.country && mapStateRef.current.region && !mapStateRef.current.lat){

                            let regionfield = "GID_1";
                            let region = mapStateRef.current.region.toString() || '';
                            let query = {
                                where: `${regionfield} = '${region}'`,
                                outFields: ['*'],
                                returnGeometry: true
                            }
                            let results = await regionLyr.queryFeatures(query);
                            if (results.features.length > 0){
                                props.mapView.goTo({
                                    target: results.features[0].geometry
                                }) 
                            }
                        }
                    } 
                }
            })()
        return () => { 
            scaleWatch.remove(); 
        };
    }, [])

    useEffect(() => {
        mapStateRef.current = mapState;
        if (lyrLoaded){
            updateDefinitionQuery();
            updateVisibility();
            updateWatchers();

        }
    }, [mapState]);



    return <>{<MapTooltip isOpen={showPopup} displaytext={regionName} mapView={props.mapView} />}</>;

}

export default RegionalBoundaries;