import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Cookies from "./Cookies";
import ButtonGroup from "../ui/ButtonGroup";
import Footer from "./Footer";
import './Legal.css';

const sectionRef = {"Terms of Use": "terms", "Privacy Policy": "privacy", "Cookie Settings": "cookies"}

function Legal(props) {
  const [isScrolled, setScrolled] = useState(false);
  const [selected, setSelected] = useState("Terms of Use");
  const termsRef = useRef(null);
  const privacyRef = useRef(null);
  const cookiesRef = useRef(null);
  let navigate = useNavigate(); 
  
  const navMap = () => {
    let path = "/map"; 
    navigate(path);
  };

  const navHome = () => {
    let path = "/"; 
    navigate(path);
  };

  const navToSection = (section) => {
    switch (section) {
      case 'terms':
        termsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'privacy':
        privacyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'cookies':
        cookiesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      default:
        termsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } 
  };

  useEffect(() => {   
    window.addEventListener("scroll", listenToScroll);
    return () => 
       window.removeEventListener("scroll", listenToScroll); 
  }, [])


  useEffect(() => {   
    if (["terms","privacy","cookies"].includes(props.currentState)) {
      navToSection(props.currentState);    
    }
    //TODO: Change this to index of list or -1
    //setSelected("terms");
  }, [props.currentState])


  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
       
    if (winScroll < 8082) { 
      setSelected("Terms of Use");
    } else if (winScroll < 8382) { 
      setSelected("Privacy Policy");
    } else {
      setSelected("Cookie Settings");
    }

    if (isScrolled && winScroll < 1200) { 
      setScrolled(false);    // to limit setting state only the first time         
    } else if (!isScrolled && winScroll > 1200) {
      setScrolled(true);
    }  
  };

  const selectSection = (event) => {
    let section = sectionRef[event.target.name]
    navToSection(section);
  };

  return (
    <div className="legal">
      <div className="head">
        <h4 className="avistep-logo" onClick={navHome}>AVISTEP</h4>
        <div className="button-group">
          <ButtonGroup 
            selected={selected} 
            buttons={["Terms of Use", "Privacy Policy", "Cookie Settings"]} 
            selectHandler={selectSection} />
        </div>
        <button className="btn-map" onClick={navMap}>Avian Mapping Tool</button>
      </div>
      <div className="info-container">
        <div ref={termsRef} className="terms-panel">
            <Terms />
        </div>
        <div ref={privacyRef} className="privacy-panel">
            <Privacy />
        </div>
        <div ref={cookiesRef} className="cookies-panel">
            <Cookies />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}



export default Legal;
