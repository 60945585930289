import React, { useRef, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import * as watchUtils from "@arcgis/core/core/watchUtils";
import Extent from "@arcgis/core/geometry/Extent";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SearchViewModel from "@arcgis/core/widgets/Search/SearchViewModel";
import SearchIcon from '@material-ui/icons/SearchSharp';


const defaultSymbol = { type: "simple-marker", size: 12, color: [0, 255, 0, 0.4], style: "x", outline: { color: [0, 255, 0, 0.4], width: 3.5 } };
const defaultSymbols = {
    point: defaultSymbol,
    polygon: { type: "simple-fill", outline: { color: [0, 62, 204, 0.4], width: 3 }, color: [0, 62, 204, 0.2] },
    polyline: { type: "simple-line", width: 15, color: [0, 62, 204, 0.3] }
}
const labelSymbol = { type: "text", font: {size: 9, weight: "bold"}, yoffset:"40px", color: [6, 76, 124, 1], haloColor: [255,255,255,0.7], haloSize: "3px"}
const highlightSymbol =  { type: "simple-marker", outline: { color: [0, 62, 204, 0.4], width: 2 }, size: 32, color: [0, 62, 204, 0] };

const useStyles = makeStyles((props) => {
    return {

        searchicon: {
            height: "0.75em"
        },
        searchwidget: {
            position: "absolute",
            top: "1.5rem",
            left: "325px",
            zIndex: "3",
            maxWidth: "270px",
            display: "grid",
            transition: "all 500ms ease-in-out",
        },
        searchtxt: {
            display: "block",
            padding: "0px",
            width: props => `${(props.focused || props.searched) ? '235px' : '0px'}`,
            borderStyle: "none",
            borderTopRightRadius: "25px",
            borderBottomRightRadius: "25px",
            background: "none",
            color: "#FFFFFF",
            transition: "all 500ms ease-in-out",
            '&:focus': {
                borderStyle: "none",
                background: "none",
                outline: "none"
            },

        },
        searchcontainer: {
            maxWidth: "270px",
            height: "35px",
            width: props => `${(props.focused || props.searched) ? '270px' : '35px'}`,
            backgroundColor: "rgba(1, 16, 51, 0.93)",
            boxShadow: "0px 0px 5px 0px rgb(68,68,68,0.25)",
            borderWidth: "0px !important",
            borderTopRightRadius: "17px",
            borderTopLeftRadius: "17px",
            borderBottomRightRadius: "17px",
            borderBottomLeftRadius: "17px",
            pointerEvents: "auto",
            textAlign: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "left",
            transition: "all 500ms ease-in-out",
        },
        results: {
            width: "100%",
            float: "left",
            fontSize: "13.5px",
            backgroundColor: "rgba(1, 16, 51, 0.93)",
            borderRadius: "5px",
            padding: "0px",
            margin: "0px",
            marginTop: "1px",
            listStyleType: "none" 
        },
        resultitem: {
            width: "100%",
            cursor: "pointer",
            background: "none",
            textAlign: "left",
            paddingLeft: "2px",
            fontSize: "11px",
            fontWeight: "500",
            color: "#FFFFFF",
            border: "1px solid transparent",
            '&:hover': {
                cursor: "pointer",
                border: "1px solid #0083CD",
                boxSizing: "border-box",
            },
            '&:first-child': {
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
            },
            '&:last-child': {
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
            },
        },
        clearBtn: {
            cursor: "pointer",
            borderRadius: "8px",
            marginLeft: "auto",
            marginRight: "10px",
            marginTop: "auto",
            marginBottom: "auto",
            color: "#FFFFFF",
            fontWeight: "500",
            padding: "2px",
            '&:hover': {
                cursor: "pointer",
                border: "1px solid #0083CD",
                boxSizing: "border-box",
            },
        },
        searchbtn: {
            borderTopRightRadius: "25px",
            borderTopLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderBottomLeftRadius: "25px",
            background: "none",
            border: "none",
            width: "35px",
            height: "35px",
            padding: "0",
            margin: "0",
            overflow: "hidden",
            cursor: "pointer",
            textAlign: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            alignItems: "center",
            '&:hover': {
                background: "none",
            },
        },
    }
})

const SearchBar = (props) => {
    const resultLayer = useRef(null);
    const viewModel = useRef(null);
    const [focused, setFocused] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [searched, setSearched] = useState(false);
    const [searchtext, setSearchText] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [results, setResults] = useState([]);
    const classes = useStyles({ focused, searched });

    const mapState = useSelector(state => state.map.mapState);




    const setText = (textitem) => {
        let searchvalue = textitem?.text ? textitem.text : textitem;
        setSearchText(searchvalue);
    }

    const performSearch = async (searchparam) => {   
        setSearched(true);
        setText(searchparam);
        let results = await viewModel.current.search(searchparam);
        if (results?.numResults && results?.numResults > 0) {
            

        } else {
            setResults([{ "name": "No results found", "feature": null, "target": null}])
        }

    };

    const clearSearch = () => {
        setSuggestions([]);
        setSearched(false);
        setSearchText("");
        setResults([]);
        resultLayer.current.removeAll();
    };

    const zoomToResult = (item) => {
        if (item.feature != null && resultLayer.current) {
            resultLayer.current.removeAll();
            let resultText = item.feature.clone();
            let resultMarker = item.feature.clone();
            setSearchText(item.name);
            resultText.symbol = { ...labelSymbol, text: `${item.name}` };
            resultMarker.symbol = highlightSymbol;
            resultLayer.current.add(resultText);
            resultLayer.current.add(resultMarker);
            centerOnFeature(item.feature.geometry);
        }
    }

    const setOnEnter = useCallback(() => {
        setFocused(true);
    });

    const setOnLeave = useCallback(() => {
        setFocused(false);
    });

    useEffect(() => {
        if (!loaded) {
            let searchView = new SearchViewModel({ view: props.mapView, autoSelect: true, defaultSymbols: defaultSymbols, maxSuggestions: 5, includeDefaultSources: true, popupEnabled: false })

            watchUtils.whenEqualOnce(searchView, "state", "ready", function () {
                // Set Default source to Esri World Geocoder
                let locatorIndex = searchView.allSources.findIndex(function (item) {
                    return item.name == "Esri World Geocoder" || item.name == "ArcGIS World Geocoding Service"
                })
                searchView.activeSourceIndex = locatorIndex;
                searchView.activeSource.resultSymbol = defaultSymbol;
                searchView.activeSource.popupEnabled = false;
                viewModel.current = searchView;
                setLoaded(true);
                

                const geocodeLyr = new GraphicsLayer({
                    id: "geocoder_results",
                    title: "geocoder_results",
                    spatialReference: { "wkid": 4326 },
                    visible: true
                });
                props.mapView.map.layers.add(geocodeLyr);
     
                resultLayer.current = geocodeLyr;
 
                geocodeLyr.when()
                    .then(async (lyr) => {
                        viewModel.current = searchView;
                        setLoaded(true);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });

        }
        return () => {
            if (resultLayer.current) {
                props.mapView.map.layers.remove(resultLayer.current);
                resultLayer.current = null;
            }
        }
    }, [])

    useEffect(() => {
        if (searchtext) {
            if (searchtext.length > 3) {
                viewModel.current.suggest(searchtext).then(function (results) {
                    if (results.numResults > 0) {
                        setSuggestions(results.results[0]["results"]);
                    } else {
                        setSuggestions([]);
                    }
                })
            }
        }
    }, [searchtext])


    useEffect(() => {
        if (loaded) {
            if (mapState.country) {
                viewModel.current.activeSource.countryCode = mapState.country
            }else {
                viewModel.current.activeSource.countryCode = null;
            }
        }
    }, [mapState])


    return <div className={classes.searchwidget} onMouseEnter={setOnEnter} onMouseLeave={setOnLeave}>
                <div className={classes.searchcontainer} >
                    <div className={classes.searchbtn}>
                        <SearchIcon className={classes.searchicon} style={{ color: "#FFFFFF" }} />
                    </div>
                    <input type="text" className={classes.searchtxt} placeholder="Search Locations" onKeyPress={e => { if (e.key === "Enter") { performSearch(e.target.value) } } } onChange={e => setSearchText(e.target.value)} value={searchtext} />
                    {(suggestions.length > 0) && searched && focused && <div className={classes.clearBtn} title="Clear search" onClick={clearSearch}>x</div>}
                </div>
                {!searched && focused && <ul className={classes.results} >
                        {suggestions && suggestions.map(function (item,i) {
                            return <div className={classes.resultitem} title={item.text} key={i} onClick={e => performSearch(item.text)}>{item.text}</div>
                        })
                        }
                    </ul>
                }
            </div>

}

export default SearchBar;
