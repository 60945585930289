import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Acknowledgements from "./Acknowledgements";
import Contact from "./Contact";
import General from "./General";
import Help from "./Help";
import ButtonGroup from "../ui/ButtonGroup";
import './About.css';

const sectionRef = {"About": "general", "Using The Tool": "help", "Acknowledgements": "acknowledgements", "Contact": "contact"}

function About(props) {
  let location = useLocation();
  const [isScrolled, setScrolled] = useState(false);
  const [selected, setSelected] = useState("general");
  const infoRef = useRef(null);
  const helpRef = useRef(null);
  const ackRef = useRef(null);
  const contactRef = useRef(null);

  let navigate = useNavigate(); 

  const navMap = () => {
    let path = "/map"; 
    navigate(path);
  };
  
  const navHome = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    navigate('#top');
  };

  const navToSection = (section) => {
    switch (section) {
      case 'general':
        infoRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        break;
      case 'help':
        helpRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        break;
      case 'acknowledgements':
        ackRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        break;
      case 'contact':
        contactRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        break;
      default:
        infoRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
    } 
  };

  useEffect(() => {   
    window.addEventListener("scroll", listenToScroll);
    return () => 
       window.removeEventListener("scroll", listenToScroll); 
  }, [])


  useEffect(() => {   
    if (["general","help","acknowledgements","contact"].includes(props.currentState)) {
      navToSection(props.currentState);    
    }
  }, [props.currentState])


  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll < 4250) { 
      setSelected("About Us");
    } else if (winScroll < 8900) { 
      setSelected("Using The Tool");
    } else if (winScroll < 13000) { 
      setSelected("Acknowledgements");
    } else {
      setSelected("Contact");
    }
    if (isScrolled && winScroll < 1200) { 
      setScrolled(false);    // to limit setting state only the first time         
    } else if (!isScrolled && winScroll > 1200) {
      setScrolled(true);
    }  
  };

  const selectSection = (event) => {
    let section = sectionRef[event.target.name]
    navToSection(section);
  };

  return (
    <div className="about">
      <div className="head">
      <h4 className="avistep-logo" onClick={navHome}>AVISTEP</h4>
        <div className="button-group">
          <ButtonGroup 
            selected={selected} 
            buttons={["About Us", "Using The Tool", "Acknowledgements", "Contact"]}
            selectHandler={selectSection} />
        </div>
        <button className="btn_map" onClick={props.navToMap} >Avian Mapping Tool</button>
      </div>
      <div className="infocontainer">
        <div ref={infoRef} className="infopanel">
            <General />
        </div>
        <div ref={helpRef} className="helppanel">
            <Help />
        </div>
        <div ref={ackRef} className="acknowpanel">
            <Acknowledgements />
        </div>
        <div ref={contactRef} className="contactpanel">
            <Contact />
        </div>
      </div>
    </div>
  );
}

export default About;