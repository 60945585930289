import React from "react";
import { useForm } from "react-hook-form";
import errorIcon from '../../styles/images/error_icon.svg';
import exitButton from '../../styles/images/exit_button.svg';
import earthIcon from '../../styles/images/earth_icon.svg';

const Contact = () => {
  const [showModal, setShowModal] = React.useState(false);

  const {register, handleSubmit, formState: { errors }} = useForm();

  return (
    <div className="contact">
      <h2 className="contactSectionHeader">CONTACT US</h2>
      <div className="contactUsSection">
        <div className="contactUsFormColumn">
          <form onSubmit={handleSubmit((data) => {
            setShowModal(!showModal);
          })}>
          <input type="text" 
            id="fullName" 
            {...register("fullName", { required: "A first and last name are required" })} 
            className="fullName" 
            style={{ border: errors.fullName ? '1px solid red' : '' }}
            placeholder="First and Last Name*"></input><br/>
          {errors.fullName && <p className="errorMessage"><img className="errorIcon" alt="Error Icon" src={errorIcon}/>{errors.fullName?.message}</p>}

          <input type="text" 
            id="email" 
            {...register("email", { required: "A valid email address is required" })} 
            className="email" 
            style={{ border: errors.email ? '1px solid red' : '' }}
            placeholder="Email Address*"></input><br/>
          {errors.email && <p className="errorMessage"><img className="errorIcon" alt="Error Icon" src={errorIcon}/>{errors.email?.message}</p>}
          
          <input type="text" 
            id="subject" 
            {...register("subject")} 
            className="subject" 
            placeholder="Subject"></input><br/>
          
          <textarea id="message" 
            {...register("message", { required: "This field is required" })} 
            className="message" 
            style={{ border: errors.message ? '1px solid red' : '' }}
            placeholder="Your Message*"></textarea><br/>
          {errors.message && <p className="errorMessage"><img className="errorIcon" alt="Error Icon" src={errorIcon}/>{errors.message?.message}</p>}
          
          <input type="submit" className="submitButton" value="Submit"></input>
          </form>
        </div>
        <div className="contactInfoColumn">
          <div>
            <p className="contactInfoSectionHeader">Email</p>
            <p className="contactInfo">AVISTEP@birdlife.org</p>
          </div>
          <div className="contactInfoSection">
            <p className="contactInfoSectionHeader">Address</p>
            <p className="contactInfo">
              BirdLife International<br/>
              The David Attenborough Building<br/>
              Pembroke Street<br/>
              Cambridge<br/>
              CB2 3QZ<br/>
              United Kingdom
            </p>
          </div>
        </div>
      </div>
      {showModal && 
        <div className="modal-background">
          <div className="modal-container">
            <p onClick={() => setShowModal(!showModal)}>
              <img className="exit-button" alt="Exit Button" src={exitButton}/>
            </p>
            <p>
              <img className="modal-icon" alt="Earth Icon" src={earthIcon}/>
            </p>
            <p className="modal-header">Form Submitted</p>
            <p className="modal-text">
              Your contact form was submitted successfully. 
              Someone from our team will be in touch over email in the next few days.
            </p>
          </div>
        </div>
      }
    </div>
  );
};

export default Contact;
