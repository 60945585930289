import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router-dom";
import About from "./About";
import Footer from "./Footer";
import logo from '../../styles/images/bl_logo.svg';
import splash_video from '../../styles/images/home_page_video.mp4';
import transition_video from '../../styles/images/transition_video.mp4';
import './Home.css';

const useStyles = makeStyles((theme) => {
  return {
  }
});

function Home() {
  const classes = useStyles();
  let location = useLocation();
  let navigate = useNavigate();
  const [anchorTarget, setAnchorTarget] = useState(null);
  const [displayTransitionVideo, setDisplayTransitionVideo ] = useState('none');
  const baseRef = useRef(null);
  const transtionVideoRef = useRef(null);
  const showTool = useSelector(state => state.config.showTool);
  
  const navMap = () => {
    baseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // Choreography for loading the AviStep mapping tool
    setDisplayTransitionVideo('block');
    transtionVideoRef.current.play();
    const timeout = setTimeout(() => {
      let path = "/map"; 
      navigate(path);
    }, 10000);
  };

  const navAbout = () => {
    //navigate('#general');
    window.scrollTo({
      top: 1100,
      left: 0,
      behavior: 'smooth'
    });
  };

  const revert = () => {

  }

  useEffect(() => {   
    if (location.hash){
      let hash = location.hash.replace("#","");
      if (["general","help","acknowledgements","contact"].includes(hash)) {
        if (showTool) {setAnchorTarget(hash);}
      } else {
        setAnchorTarget(null);
      }
    }
  }, [location,showTool])

  return (
    <div>
      <video className="splash-video" autoPlay loop muted>
        <source src={splash_video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video ref={transtionVideoRef} className="transition-video"style={{ 'display': displayTransitionVideo}} muted>
        <source src={transition_video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div ref={baseRef}  className="home">
        <div className="left">
          <div className="title">
            <h2>AVISTEP</h2>
          </div>
          {showTool && <h4 className="sub-title">The Avian Sensitivity Tool for Energy Planning</h4>}
          {!showTool && <h4 className="sub-title">The Avian Sensitivity Tool for Energy Planning is <strong><i>coming soon!</i></strong></h4>}
            <div className="button-list">
              
              {showTool && <div className="btn">
                <button className="btn-white" onClick={navMap} ><div className="buttton-text">Get Started</div></button>
              </div>}
              {showTool && <div className="btn">
                <button className="btn-clear" onClick={navAbout} >Learn More</button>
              </div>}
            </div>

          <div className="foot">
            <img className="logo" alt="BirdLife logo" href="https://www.birdlife.org" src={logo}/>
          </div>
        </div>
      </div>
      <div className="general">
      </div>
      {showTool && <About currentState={anchorTarget} navToMap={navMap} ></About> }
      {showTool && <Footer></Footer>}
    </div>
  );
}

export default Home;