import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Caption from "../../../ui/shared/Caption";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuSharpIcon from '@material-ui/icons/MenuSharp';
import DropDown from "../../../ui/DropDown";
import { FlagIconSource } from "../../../../utils/imagesource";
import './LeftHeader.css';


const LeftHeader = (props) => {
    const [country, setCountry] = useState(null);
    const mapState = useSelector(state => state.map.mapState);
    const countries = useSelector(state => state.config.countries);


    useEffect(() => {

    }, [])

    useEffect(() => {
        if (mapState.country){
            let countrycurrent = countries.find(countryitem => countryitem.code == mapState.country);
            if (countrycurrent){ setCountry(countrycurrent)}
        }
    }, [mapState, countries])



    return (
        <div className="l-header">
            <div className="l-header-root">
                <div className="l-header-menu" onClick={props.openMenu} title="Main Menu"><MenuSharpIcon/></div>
                <div className="l-header-title"><Caption>AVISTEP</Caption></div>             
            </div>
        </div>
    )

}

export default LeftHeader;

