import React, { useRef, useEffect, useState } from "react";
import { useSearchParams, useLocation, useNavigate  } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ImageReference } from "../../../utils/imagesource";
import { clearReports } from "../../../redux/reducers/map";


import styled from "styled-components";
import './Avistep.css';

// Component imports
import ContentContainer from "../../ui/ContentContainer";
import PDFModal from "../../ui/PDFModal";
import LeftPanel from "./leftpanel/LeftPanel";
import RightPanel from "./rightpanel/RightPanel";
import Map from "./maputils/Map";
import ZoomControl from "./maputils/ZoomControl";
import SearchBar from "./maputils/SearchBar";
import BaseMapPicker from "./maputils/basemapwidget/BaseMapPicker";
import MapActions from "./maputils/MapActions";
import LoadScreen from "../../LoadScreen";


const useStyles = makeStyles((props) => {
    return {
        logo: {
            left: "325px",
            position: "absolute",
            zIndex: "3",
            bottom: "2rem",
        },
    }
})

const Avistep = props => {
    const dispatch = useDispatch();
    let location = useLocation();
    const navigate = useNavigate ();
    let [mapView, setMapView] = useState();
    let [loaded, setLoaded] = useState(false);
    let [layersLoading, setLayersLoading] = useState(true);
    let [viewPDF, setViewPDF] = useState(false);
    const [right, setRightPosition] = useState('2rem');
    const classes = useStyles({ right });
    const config = useSelector(state => state.config);
    const cellSelected = useSelector(state => state.map.cellSelected);
    const showCellReport = useSelector(state => state.map.showCellReport);
    const showCountryReport = useSelector(state => state.map.showCountryReport);

    let logoref = ImageReference['LOGO_TRANSPARENT'] || null;

    useEffect(() => {
        if (!loaded && !config.error && config.loaded){
            setLoaded(true);
        }
    }, [config])

    useEffect(() => {
        if (cellSelected) {
            setRightPosition("400px");
        }else {
            setRightPosition("2rem");
        }
    }, [cellSelected])


    useEffect(() => {
        if (showCellReport || showCountryReport) {
            setViewPDF(true);
        }
    }, [showCellReport, showCountryReport])


    // mapView is created in the Map component, but the state is raised to here
    // and stored here and passed into the other components.
    const handleMapReady = (view) => {
        setMapView(view);
    };

    const setLayersStatus = (bool) => {
        setLayersLoading(!bool);
    };

    const closePDF = () => {
        setViewPDF(false);
        dispatch(clearReports());
    };


    return (<>
            
              {!loaded && <ContentContainer>
                    <LoadScreen isLoading={true} />
                  </ContentContainer>  
              }
              {loaded &&  <ContentContainer>
                {viewPDF && <PDFModal onClose={closePDF}/>}
                  {layersLoading && <div className="progress"><CircularProgress color="inherit" size="4rem"/></div>}
                    <div className={classes.logo}>
                        <img src={logoref} />
                    </div>
                    <LeftPanel mapView={mapView} />
                    <Map id="aviViewDiv" className="map" mapViewReady={handleMapReady} layersStatus={setLayersStatus}/>
                    <RightPanel mapView={mapView} />
                    {mapView && <ZoomControl id="esriZoom" mapView={mapView}/>}
                    {mapView && <SearchBar id="esriSearch" mapView={mapView} />}
                    {mapView && <BaseMapPicker id="esriBase" mapView={mapView} />}
                    {mapView && <MapActions />}
                    
                </ContentContainer> }
            </>
    );
};

export default Avistep;



