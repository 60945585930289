import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import HindMaduraiBold from "../../../styles/fonts/HindMadurai-Bold.ttf";
import WorkSans from "../../../styles/fonts/WorkSans-Regular.ttf";
import { ImageReference, IconSource } from "../../../utils/imagesource";


Font.register({
  family: 'HindMaduraiBold',
  format: "truetype",
  src: HindMaduraiBold
});
Font.register({
  family: 'Work Sans',
  format: "truetype",
  src: WorkSans
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    height:'100%',
    paddingBottom: '75pt'
  },
  pageview: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
  header: {
    flexGrow: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '3.5in',
    backgroundColor: '#102646',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '25pt'
  },
  headerfix:{
    height: '2.75in',
    backgroundColor: '#102646',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '25pt'
  },
  header1: {
    height:'2.75in',
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    paddingTop: '15pt',
    paddingBottom: '15pt',
    paddingLeft: '20pt',
    gap: '10pt'
  },
  header2: {
    height:'2.75in',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '15pt',
    paddingBottom: '15pt',
    paddingRight: '20pt',
    gap: '10pt'
  },
  avistep: {
    fontFamily: 'HindMaduraiBold',
    fontWeight: 700,
    fontSize: '14pt',
    paddingRight: '5pt'
  },
  logo: {
    width: '42pt',
    height: '34pt',
    marginRight: '1pt',
    marginLeft: 'auto'
  },
  pinicon: {
    height: '8pt',
    paddingRight: '5pt'
  },
  titlesection: {
    paddingTop: '10pt',
    marginLeft: '20pt',
    marginRight: '20pt',
    height: '50pt',
    paddingBottom: '5pt'
  },
  mapsection: {
    margin: '15pt',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20pt',
    justifyContent: 'center',
    alignItems: 'center',
    height: '500pt'
  },
  mapimage: {
    paddingBottom: '10pt',
    width: '95%',
    height: 'auto',
    display: 'block'
  },
  lcsection: {
    margin: '15pt',
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20pt',
    justifyItems: 'start',
    alignItems: 'center'
  },
  lcimage: {
    width: 'auto',
    height: '95%',
    display: 'block'
  },
  mainsection: {
    margin: '15pt',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '20pt',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'green',
  },
  sitessection: {
    paddingLeft: '20pt',
    paddingRight: '20pt',
    paddingBottom: '100pt' 
  },
  speciessection: {
    paddingLeft: '20pt',
    paddingRight: '20pt',
  },
  sectiontitle: { 
    fontSize: '16pt', 
    fontFamily:'Work Sans', 
    fontWeight: 400, 
    color: 'black',
  },
  lctitle: { 
    fontSize: '16pt', 
    fontFamily:'Work Sans', 
    fontWeight: 400, 
    color: 'black',
    paddingBottom: '5pt'
  },
  sitesheader: {
    width: '100%',
    height: '30pt',
    flexDirection: 'row',
    marginTop: '20pt'
  },
  speciesheader: {
    width: '100%',
    height: '30pt',
    flexDirection: 'row',
    marginTop: '20pt'
  },
  sitesrow: {
    width: '100%',
    height: '30pt',
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: '0.75pt',
    borderTopColor: 'rgba(0, 0, 0, .1)',
    borderTopStyle: 'solid',
  },
  speciesrow: {
    width: '100%',
    height: '.9in',
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: '0.75pt',
    borderTopColor: 'rgba(0, 0, 0, .1)',
    borderTopStyle: 'solid',
  },
  footer: {
    height: '10%'
  },
  pagenum: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    paddingBottom: '10pt',
    textAlign: 'center',
    height: '50pt',
    marginTop: '25pt',
    fontSize: '9pt', 
    fontFamily:'Work Sans', 
    fontWeight: 400
  },
});


let logoref = ImageReference['LOGO_TRANSPARENT'] || null;

const sensitivityDescriptions = {
  1: ['Sensitivity assessed as ','Low','. There is a low probability that installation here would effect local species.'],
  2: ['Sensitivity assessed as ','Moderate','. There is a moderate probability that installation here would effect local species.'],
  3: ['Sensitivity assessed as ','High','. There is a strong probability that installation here would effect local species.'],
  4: ['Sensitivity assessed as ','Very High','. There is a very strong probability that installation here would effect local species.'],
}

// Create Document Component
const CellReport = (props) => {

  const score = `Sensitivity Score: ${props.data.sensitivity_score}%`;
  const assessmentTitle = props.data.energy ? `${props.data.energy.label} Assessment` : "";
  const description = sensitivityDescriptions[props.data.sensitivity_rank] || "";

  const confidenceName = {
    '1': 'High',
    '2': 'Medium',
    '3': 'Low'
  }

  const getStatus = {
    "EN": "Endangered (EN)",
    "CR": "Critically Endangered (CR)",
    "VU": "Vulnerable (VU)",
    "EX": "Extinct (EX)",
    "NT": "Nearly Threatened (NT)",
    "LC": "Least Concern (LC)",
    "EW": "Extinct in the Wild (EW)"
  }

  const getPercent = (sensitivity) => {
      if (sensitivity){
        return sensitivity * 100
      }else{
        return ""
      }
  }
    
return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
              <View style={styles.headerfix} fixed>
                <View style={styles.header1}>
                  <View style={{ height: '50%', textAlign: 'left', display: 'flex',flexDirection: 'row', gap: '10pt' }}>
                      <Text style={styles.avistep}>AVISTEP </Text><Text style={{ fontSize: '14pt', fontFamily:'Work Sans', fontWeight: 300 }}> {props.data.country}</Text>
                  </View>
                  <View style={{ height: '20%', textAlign: 'left' }}>
                      <Text style={{ fontSize: '16pt', fontFamily:'Work Sans', fontWeight: 400 }}>{assessmentTitle}</Text>
                  </View>
                  <View style={{ height: '20%', textAlign: 'left', display: 'flex',flexDirection: 'row', gap: '10px' }}>
                      <Image src={IconSource['LOCATIONPIN_PNG']} style={styles.pinicon}/><Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400 }}>{props.data.coordinates}</Text>
                  </View>
                </View>
                <View style={styles.header2}>
                  <View style={{ height: '50%', textAlign: 'right' }}>
                        <Image src={logoref} style={styles.logo}/>
                  </View>
                  <View style={{ height: '20%', textAlign: 'right' }}>
                      
                  </View>
                  <View style={{ height: '20%', textAlign: 'right' }}>
                    <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400 }}>{score}</Text>
                  </View>
                </View>
                  
              </View>
              <View style={styles.titlesection}>
                  <View style={styles.sectiontitle}>
                  {description[1] == 'Low' && <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 300, color: 'black' }}>
                      {description[0]}<Text style={{ fontFamily: 'Helvetica-Bold', color: 'green' }}>{description[1]}</Text>{description[2]}
                    </Text>}
                    {description[1] == 'Moderate' && <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 300, color: 'black' }}>
                      {description[0]}<Text style={{ fontFamily: 'Helvetica-Bold', color: '#E4D00A' }}>{description[1]}</Text>{description[2]}
                    </Text>}
                    {description[1] == 'High' && <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 300, color: 'black' }}>
                      {description[0]}<Text style={{ fontFamily: 'Helvetica-Bold', color: '#F28C28' }}>{description[1]}</Text>{description[2]}
                    </Text>}
                    {description[1] == 'Very High' && <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 300, color: 'black' }}>
                      {description[0]}<Text style={{ fontFamily: 'Helvetica-Bold', color: 'red' }}>{description[1]}</Text>{description[2]}
                    </Text>}
                  </View>
              </View>
              <View style={styles.mapsection}>
                  <Image src={props.data.region_map.dataUrl} style={styles.mapimage}/>
                  <Image src={props.data.cell_map.dataUrl} style={styles.mapimage}/>
              </View>            
            
              {props.data.sites.length > 0 && <View style={styles.sitessection} break>
                  <Text style={styles.sectiontitle}>Sensitive Sites</Text>
                  <View style={styles.sitesheader}>
                      <View style={{width: '50%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '10pt'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Site</Text>
                      </View>
                      <View style={{width: '50%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Type</Text>
                      </View>
                  </View>

                  {props.data.sites && props.data.sites.map((site, index) => {
                      return (
                        <View style={styles.sitesrow}>
                          <View style={{width: '50%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '10pt'}}>
                            <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{site.site}</Text>
                          </View>
                          <View style={{width: '50%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                            <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{site.type}</Text>
                          </View>
                      </View>
                      )
                  })}

              </View>}
              {props.data.species.length > 0 && <View style={styles.speciessection} wrap>
                  <Text style={styles.sectiontitle} fixed>Sensitive Species</Text>
                  <View style={styles.speciesheader} fixed>
                      <View style={{width: '26%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '10pt'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Species</Text>
                      </View>
                      <View style={{width: '25%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Scientific Name</Text>
                      </View>
                      <View style={{width: '25%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Status</Text>
                      </View>
                      <View style={{width: '12%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Sensitivity</Text>
                      </View>
                      <View style={{width: '12%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                        <Text style={{ fontSize: '8pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>Confidence</Text>
                      </View>
                  </View>
                  
                  {props.data.species && props.data.species.map((bird, index) => {
                      return (
                        <View style={styles.speciesrow} wrap={false}>
                            <View style={{width: '26%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '10pt'}}>
                              <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{bird.common_name}</Text>
                            </View>
                            <View style={{width: '25%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424'}}>{bird.scientific_name}</Text>
                            </View>
                            <View style={{width: '25%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{getStatus[bird.rl_category] || bird.rl_category}</Text>
                            </View>
                            <View style={{width: '12%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              {props.data.energy.code == 'ONSHORE' && <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424' }}>{getPercent(bird.sensitivity_wind).toFixed(1)}</Text>}
                            </View>
                            <View style={{width: '12%', textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}}>
                              <Text style={{ fontSize: '10pt', fontFamily:'Work Sans', fontWeight: 400, color: '#242424'}}>{confidenceName[bird.sps_confidence] || ""}</Text>
                            </View>
                        </View>
                      )
                  })}
              </View>}
              {props.data.energy.code != 'OFFSHORE' && <View style={styles.sitessection} break>
                  <Text style={styles.lctitle}>Landcover Sensitivity</Text>
                  <View style={styles.lcsection}>
                    <Image src={props.data.chartImage.dataUrl} style={styles.lcimage}/>
                  </View> 
              </View>}
                  
              <View style={styles.pagenum} fixed>
                <Text render={({ pageNumber, totalPages }) => (
                `${pageNumber} of ${totalPages}`
                  )}  />
              </View>
            </Page>
        </Document>
    )
};


export default CellReport;