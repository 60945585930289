// React imports
import React, { useEffect, useState } from "react";
import { Route, Routes, useHistory, Redirect, BrowserRouter } from "react-router-dom";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { fetchMapConfig } from "../redux/reducers/config";
import { pages } from "../redux/reducers/route";

// Component imports
import Main from "./Main";
import ApplicationError from "./ApplicationError"
import LoadScreen from "./LoadScreen";



// Component definition
const App = props => {
    //const { pathname } = props.location;
    // redux store state
    const mapconfig = useSelector(state => state.config);
    const route = useSelector(state => state.route);
    const dispatch = useDispatch();

    // when the component mounts request the mapconfig and load it into the Redux state
    useEffect(() => {
        dispatch(fetchMapConfig());
    }, [dispatch]);


    // RENDER RETURN
    // app is initializing for the following reasons, show the load screen
    // 1. Map & Layer config is not yet loaded  

    if (!mapconfig.error && !mapconfig.loaded) {
        return <LoadScreen isLoading={true} />;
    }


    // determine current page //component={ApplicationError}  component={Main} 
    var curr_page;
    switch (route.page) {
        case (pages.ERROR_PAGE__GENERAL):
            curr_page = <ApplicationError />//<Route path="/*" element={<ApplicationError />}/>
            break;
        case (pages.MAIN_PAGE):
            curr_page = <Main />// <Route path="/*" element={<Main />} />
            break;
        default:
            curr_page = <Main />
    }

    // App is initialized and config is available, route to main component
    return (
        <>
            <div style={{ minHeight: "100vh" }}>
                
                {curr_page}
                
            </div>
                
        </>
    );
};

export default App;
