const allIcons = require.context('../styles/icons', true); 
const flagIcons = require.context('../styles/icons/flags', true); 
const imgReference = require.context('../styles/images', true);
const rightPanelReference = require.context('../styles/images/right_panel', true);

//import { ReactComponent as SolarIcon } from '../styles/icons/solar_pv_icon.svg';
//import { ReactComponent as WindOnshoreIcon } from '../styles/icons/onshore_wind_icon.svg';
//import { ReactComponent as PowerTransIcon } from '../styles/icons/powerline_icon.svg';
//import { ReactComponent as PowerDistIcon } from '../styles/icons/powerline_icon.svg';


export const FlagIconSource = {
    'IND': flagIcons('./india_icon.svg').default,
    'NPL': flagIcons('./nepal_icon.svg').default,
    'THA': flagIcons('./thailand_icon.svg').default,
    'VNM': flagIcons('./vietnam_icon.svg').default
}

export const IconSource = {
    'SOLAR': allIcons('./solar_pv_icon.svg').default,
    'ONSHORE': allIcons('./onshore_wind_icon.svg').default,
    'OFFSHORE': allIcons('./offshore_wind_icon.svg').default,
    'POWER': allIcons('./powerline_icon.svg').default,
    'PWTRAN': allIcons('./transmission_powerline_icon.svg').default,
    'PWDIST': allIcons('./distribution_powerline_icon.svg').default,
    'EXPANDMORE': allIcons('./expand_more.svg').default,
    'EXPANDLESS': allIcons('./expand_less.svg').default,
    'NAVBACK': allIcons('./navback_icon.svg').default,
    'COLLAPSE': allIcons('./collapse_icon.svg').default,
    'LOCATIONPIN': allIcons('./location_pin_icon.svg').default,
    'LOCATIONPIN_PNG': allIcons('./location_pin_icon.png').default,
    'PRINT': allIcons('./print_icon.svg').default,
    'SHARE': allIcons('./share_icon.svg').default    
}

export const RightPanelImageSource = {
    'INDIAOFFSHORE': rightPanelReference('./india_offshore_wind.png').default,
    'INDIAONSHORE': rightPanelReference('./india_onshore_wind.png').default,
    'INDIAPWDIST': rightPanelReference('./india_powerline_distribution.png').default,
    'INDIAPWTRAN': rightPanelReference('./india_powerline_transmission.png').default,
    'INDIASOLAR': rightPanelReference('./india_solar.png').default,
    'NEPALONSHORE': rightPanelReference('./nepal_onshore_wind.png').default,
    'NEPALPWDIST': rightPanelReference('./nepal_powerline_distribution.png').default,
    'NEPALPWTRAN': rightPanelReference('./nepal_powerline_transmission.png').default,
    'NEPALSOLAR': rightPanelReference('./nepal_solar.png').default,
    'THAILANDOFFSHORE': rightPanelReference('./thailand_offshore_wind.png').default,
    'THAILANDONSHORE': rightPanelReference('./thailand_onshore_wind.png').default,
    'THAILANDPWDIST': rightPanelReference('./thailand_powerline_distribution.png').default,
    'THAILANDPWTRAN': rightPanelReference('./thailand_powerline_transmission.png').default,
    'THAILANDSOLAR': rightPanelReference('./thailand_solar.png').default,
    'VIETNAMOFFSHORE': rightPanelReference('./vietnam_offshore_wind.png').default,
    'VIETNAMONSHORE': rightPanelReference('./vietnam_onshore_wind.png').default,
    'VIETNAMPWDIST': rightPanelReference('./vietnam_powerline_distribution.png').default,
    'VIETNAMPWTRAN': rightPanelReference('./vietnam_powerline_transmission.png').default,
    'VIETNAMSOLAR': rightPanelReference('./vietnam_solar.png').default,
    'CORALREEF': rightPanelReference('./coral_reef.png').default,
    'MANGROVE': rightPanelReference('./mangrove.png').default,
    'SEAGRASS': rightPanelReference('./seagrass.png').default

}
    
export const ImageReference = {
    'LOGO_TRANSPARENT': imgReference('./bli_logo_transparent50.png').default
}

export const Icon = (name) => {
    return IconSource[name];
}
